import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { auth } from '../firebase-config';
import { useLocation } from 'react-router-dom';
import { ChakraProvider, Spinner } from '@chakra-ui/react';

function Authflow() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageName = queryParams.get('redirect');
  const navigate = useNavigate();

  useEffect(() => {
    function goHome() {
      if (pageName == null) {
        navigate('/');
      } else if (pageName === 'redeem') {
        navigate('/redeem');
      } else {
        navigate(`/events/${pageName}`);
      }
    }
    function missingProfile() {
      if (pageName == null) {
        navigate('/createuser');
      } else {
        navigate(`/createuser?redirect=${pageName}`);
      }
    }

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user == null) {
        navigate('/');
      } else if (user.email == null) {
        missingProfile();
      } else {
        // User is signed out
        goHome();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate, pageName]);

  return (
    <ChakraProvider>
      <div
        style={{
          textAlign: 'center',
          fontFamily: 'Montserrat, sans-serif',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif',
            top: '50%',
            transform: 'translateY(100%)',
          }}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      </div>
    </ChakraProvider>
  );
}

export default Authflow;
