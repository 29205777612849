import React from 'react';
import { ChakraProvider, theme, Text, Flex } from '@chakra-ui/react';

function NotFound() {
  return (
    <ChakraProvider theme={theme}>
      <Flex
        height="80vh"
        flex="1"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Text textColor="white" textAlign="center">
          Error 404: Page Not Found
        </Text>
        <Text textColor="white" textAlign="center">
          No page found at the URL you specified.
        </Text>
      </Flex>
    </ChakraProvider>
  );
}

export default NotFound;
