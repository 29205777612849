import React, { useState } from 'react';
import {
  Text,
  Button,
  VStack,
  Box,
  Stack,
  DrawerBody,
  DrawerFooter,
} from '@chakra-ui/react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../firebase-config';
import { useToast } from '@chakra-ui/react';
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

const NYE_EVENT_ID = 'gatsbygala24';

const MyPaymentForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const [payButtonLoading, setPayButtonLoading] = useState(false);

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    // window.onbeforeunload = function () {
    //   return 'Are you sure you want to leave? Your ticket is being assigned.';
    // };

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPayButtonLoading(true);
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'https://app.wavenightlife.com/success',
      },
    });

    var addUserTickets = null;

    if (error) {
      setPayButtonLoading(false);
      toast({
        title: 'Purchase failed.',
        description:
          'Double check your card number and try again. If the problem persists, contact Wave Support.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      });
    } else {
      if (props.numTickets * props.ticketPackSize > 1) {
        props.openDrawerFunction();
        addUserTickets = await fetch(
          'https://us-central1-wave-dynamics.cloudfunctions.net/adminapi/generateTicket',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              authorization: process.env.REACT_APP_FIREBASE_API_AUTH_KEY,
            },
            body: JSON.stringify({
              uid: auth.currentUser.uid,
              pass: props.passDetails,
              numTickets: props.numTickets,
              ticketPackSize: props.ticketPackSize,
            }),
          }
        )
          .then(response => response.json())
          .then(response => {
            return response.message;
          })
          .catch(error => {
            console.error('Error:', error);
          });
      } else {
        const docRef = doc(db, 'Users', auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        let tempPasses = docSnap.data()['passes'];
        var tempPassDetails = props.passDetails;
        tempPassDetails['numTickets'] = props.numTickets * props.ticketPackSize;
        tempPasses.push(tempPassDetails);
        await updateDoc(docRef, {
          passes: tempPasses,
          updateRequired: true,
        });
        addUserTickets = 'success';
      }

      if (
        addUserTickets === 'success' ||
        addUserTickets === 'Ticket with codes generated and added successfully'
      ) {
        toast({
          title: 'Purchase was successful!',
          description:
            'Go to your profile page to view your passes and tickets.',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'bottom-right',
        });
      } else {
        toast({
          title: 'Purchase failed.',
          description:
            'Double check your card number and try again. If the problem persists, contact Wave Support.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      }

      props.closeFunction();
      setPayButtonLoading(false);
      props.setCodeLoading(false);
      // window.onbeforeunload = null;
    }
  };
  if (props.id === NYE_EVENT_ID && props.passDetails.passName.includes('VIP')) {
    return (
      <DrawerBody>
        <form>
          <Stack spacing="24px">
            <Box>
              <AddressElement options={{ mode: 'billing' }} />
              <PaymentElement />
            </Box>
            <DrawerFooter
              borderTopWidth="1px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <VStack>
                {props.ticketPackSize > 1 ? (
                  <Box textAlign="center">
                    <Text>
                      Subtotal (${props.origPrice / 100} x {props.numTickets}{' '}
                      {props.ticketPackSize}-pack): $
                      {(
                        Number(props.origPrice / 100) * props.numTickets
                      ).toFixed(2)}
                    </Text>
                  </Box>
                ) : (
                  <Box textAlign="center">
                    <Text>
                      Subtotal (${props.origPrice / 100} x {props.numTickets}):
                      $
                      {(
                        Number(props.origPrice / 100) * props.numTickets
                      ).toFixed(2)}
                    </Text>
                  </Box>
                )}
                <Text>
                  Service Fee: $
                  {(
                    Number(props.origPrice / 100) *
                    props.numTickets *
                    0.15
                  ).toFixed(2)}
                </Text>
                <Text>
                  Local Sales Tax: $
                  {(
                    Number(props.origPrice / 100) *
                    props.numTickets *
                    1.15 *
                    0.0925
                  ).toFixed(2)}
                </Text>
                <Text>
                  Staff Gratuity: $
                  {(
                    Number(props.origPrice / 100) *
                    props.numTickets *
                    0.25
                  ).toFixed(2)}
                </Text>
                <Text>
                  Liquor Tax: $
                  {(
                    Number(props.origPrice / 100) *
                    props.numTickets *
                    0.15
                  ).toFixed(2)}
                </Text>
                <Button
                  colorScheme="blue"
                  disabled={!stripe}
                  onClick={handleSubmit}
                  style={{ width: '100%' }}
                  isLoading={payButtonLoading}
                  loadingText="Processing"
                >
                  Pay ${Number(props.passPrice / 100).toFixed(2)}
                </Button>
              </VStack>
            </DrawerFooter>
          </Stack>
        </form>
      </DrawerBody>
    );
  } else if (
    props.id === NYE_EVENT_ID &&
    !props.passDetails.passName.includes('VIP')
  ) {
    return (
      <DrawerBody>
        <form>
          <Stack spacing="24px">
            <Box>
              <AddressElement options={{ mode: 'billing' }} />
              <PaymentElement />
            </Box>
            <DrawerFooter
              borderTopWidth="1px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <VStack>
                {props.ticketPackSize > 1 ? (
                  <Box textAlign="center">
                    <Text>
                      Subtotal (${props.origPrice / 100} x {props.numTickets}{' '}
                      {props.ticketPackSize}-pack): $
                      {(
                        Number(props.origPrice / 100) * props.numTickets
                      ).toFixed(2)}
                    </Text>
                  </Box>
                ) : (
                  <Box textAlign="center">
                    <Text>
                      Subtotal (${props.origPrice / 100} x {props.numTickets}):
                      $
                      {(
                        Number(props.origPrice / 100) * props.numTickets
                      ).toFixed(2)}
                    </Text>
                  </Box>
                )}
                <Text>
                  Service Fee: $
                  {(
                    Number(props.origPrice / 100) *
                    props.numTickets *
                    0.15
                  ).toFixed(2)}
                </Text>
                <Text>
                  Local Sales Tax: $
                  {(
                    Number(props.origPrice / 100) *
                    props.numTickets *
                    1.15 *
                    0.0925
                  ).toFixed(2)}
                </Text>
                <Text>
                  Staff Gratuity: $
                  {(
                    Number(props.origPrice / 100) *
                    props.numTickets *
                    0.25 *
                    0.5
                  ).toFixed(2)}
                </Text>
                <Text>
                  Liquor Tax: $
                  {(
                    Number(props.origPrice / 100) *
                    props.numTickets *
                    0.15 *
                    0.5
                  ).toFixed(2)}
                </Text>
                <Button
                  colorScheme="blue"
                  disabled={!stripe}
                  onClick={handleSubmit}
                  style={{ width: '100%' }}
                  isLoading={payButtonLoading}
                  loadingText="Processing"
                >
                  Pay ${Number(props.passPrice / 100).toFixed(2)}
                </Button>
              </VStack>
            </DrawerFooter>
          </Stack>
        </form>
      </DrawerBody>
    );
  } else {
    return (
      <DrawerBody>
        <form>
          <Stack spacing="24px">
            <Box>
              <AddressElement options={{ mode: 'billing' }} />
              <PaymentElement />
            </Box>
            <DrawerFooter
              borderTopWidth="1px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <VStack>
                {props.ticketPackSize > 1 ? (
                  <Box textAlign="center">
                    <Text>
                      Subtotal (${props.origPrice / 100} x {props.numTickets}{' '}
                      {props.ticketPackSize}-pack): $
                      {(
                        Number(props.origPrice / 100) * props.numTickets
                      ).toFixed(2)}
                    </Text>
                  </Box>
                ) : (
                  <Box textAlign="center">
                    <Text>
                      Subtotal (${props.origPrice / 100} x {props.numTickets}):
                      $
                      {(
                        Number(props.origPrice / 100) * props.numTickets
                      ).toFixed(2)}
                    </Text>
                  </Box>
                )}

                <Text>
                  Service Fee: $
                  {(
                    Number(props.origPrice / 100) *
                    props.numTickets *
                    props.serviceFeeRate
                  ).toFixed(2)}
                </Text>
                <Text>
                  Local Sales Tax: $
                  {(
                    Number(props.origPrice / 100) *
                    props.numTickets *
                    (1 + props.serviceFeeRate) *
                    props.taxRate
                  ).toFixed(2)}
                </Text>
                <Button
                  colorScheme="blue"
                  disabled={!stripe}
                  onClick={handleSubmit}
                  style={{ width: '100%' }}
                  isLoading={payButtonLoading}
                  loadingText="Processing"
                >
                  Pay ${Number(props.passPrice / 100).toFixed(2)}
                </Button>
              </VStack>
            </DrawerFooter>
          </Stack>
        </form>
      </DrawerBody>
    );
  }
};
export { MyPaymentForm };
