import React, { useEffect, useState, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Spinner, useToast } from '@chakra-ui/react';
// import moment from 'moment';
import {
  ChakraProvider,
  // Heading,
  Badge,
  Text,
  Button,
  Center,
  Image,
  Input,
  Box,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormErrorMessage,
} from '@chakra-ui/react';
import { db, auth } from '../firebase-config.js';
import {
  getDoc,
  doc,
  getDocs,
  where,
  collection,
  query,
} from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router';
import ButtonTheme from '../components/Button.js';
import { useMediaQuery } from 'react-responsive';

function Profile() {
  // const {
  //   isOpen: isFriendsOpen,
  //   onOpen: onFriendsOpen,
  //   onClose: onFriendsClose,
  // } = useDisclosure();
  const {
    isOpen: isQROpen,
    onOpen: onQROpen,
    onClose: onQRClose,
  } = useDisclosure();
  const {
    isOpen: isCancelPendingOpen,
    onOpen: onCancelPendingOpen,
    onClose: onCancelPendingClose,
  } = useDisclosure();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(new Map());
  const [codeStatus, setCodeStatus] = useState(new Map());
  const [eventTime, setEventTime] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCode, setSelectedCode] = useState(null);
  const [smsNumber, setSmsNumber] = useState('+1');
  const [isInvalid, setIsInvalid] = useState(true);
  // const [sentRequests, setSentRequests] = useState([]);
  // const [recvdReqs, setRecvdRequests] = useState([]);
  // const [names, setNames] = useState({});
  // const [friends, setFriends] = useState([]);
  // const [requestingFriend, setRequestingFriend] = useState(false);
  // const [friendRequestsChanged, setFriendRequestsChanged] = useState(false);
  // const [ticketTransferLoading, setTicketTransferLoading] = useState(false);
  // const [view, setView] = useState('friends');
  // const [loadingStates, setLoadingStates] = useState({});
  const [loadingSendTicket, setLoadingSendTicket] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [qrCode, setQRCode] = useState(null);
  const [loadingCancelPendingTicket, setLoadingCancelPendingTicket] =
    useState(false);
  const toast = useToast();
  const prevCodeStatusRef = useRef(codeStatus);
  const cancelPendingTicketRef = useRef({ selectedCode: '', phone: '' });

  const openQRModal = link => {
    setQRCode(link);
    onQROpen();
  };

  const handleInviteClick = code => {
    setSelectedCode(code);
    onOpen();
  };

  const openCancelTransferModal = (selectedCode, phone) => {
    cancelPendingTicketRef.current = {
      selectedCode: selectedCode,
      phone: phone,
    };
    onCancelPendingOpen();
  };

  const handleCancelTransfer = selectedCode => {
    setLoadingCancelPendingTicket(true);
    const functions = getFunctions();
    const cancelTransfer = httpsCallable(functions, 'deletePendingTicket');
    cancelTransfer({ code: selectedCode }).then(result => {
      // Read result of the Cloud Function.
      /** @type {any} */
      const data = result.data;
      setLoadingCancelPendingTicket(false);
      onCancelPendingClose();
      if (data.status === 200) {
        toast({
          title: 'Ticket transfer cancelled successfully!',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
        setRefreshTrigger(!refreshTrigger);
      } else if (data.status === 404) {
        toast({
          title: 'No matching ticket found.',
          description: 'Please contact Wave Support.',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      } else {
        toast({
          title: 'System Error while cancelling ticket transfer.',
          description: 'Please contact Wave Support.',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    });
  };

  function signOutFunction() {
    signOut(auth);
    window.location.reload(false);
  }

  function goToRedeem() {
    navigate('/redeem');
  }

  async function sendTicketToNumber(phone, ticketCode) {
    setLoadingSendTicket(true);
    const functions = getFunctions();
    const assignTicket = httpsCallable(functions, 'assignTicket');
    assignTicket({ receiver: phone, code: ticketCode })
      .then(result => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        if (data.status === 200) {
          toast({
            title: 'Ticket transfer successful!',
            description: 'We hope you enjoy your Wave Product!',
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'bottom-right',
          });
          setLoadingSendTicket(false);
          onClose();
          setRefreshTrigger(!refreshTrigger);
          // TODO: loading while the page refreshes
        } else {
          toast({
            title: 'Ticket transfer failed.',
            description:
              'The user might already have this pass. If not, contact Wave Support.',
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'bottom-right',
          });
          setLoadingSendTicket(false);
        }
      })
      .catch(error => {
        toast({
          title: 'Ticket transfer failed.',
          description:
            'The user might already have this pass. If not, contact Wave Support.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
        setLoadingSendTicket(false);
      });
  }

  // async function sendTicket(uid, ticketCode) {
  //   setTicketTransferLoading(true);
  //   const phone = await getNumFromUID(uid);
  //   const functions = getFunctions();
  //   const assignTicket = httpsCallable(functions, 'assignTicket');
  //   assignTicket({ receiver: phone, code: ticketCode })
  //     .then(result => {
  //       // Read result of the Cloud Function.
  //       /** @type {any} */
  //       const data = result.data;
  //       // console.log(data);
  //       if (data.status === 200) {
  //         toast({
  //           title: 'Ticket transfer successful!',
  //           description: 'We hope you enjoy your Wave Product!',
  //           status: 'success',
  //           duration: 9000,
  //           isClosable: true,
  //           position: 'bottom-right',
  //         });
  //         onClose();
  //       } else {
  //         toast({
  //           title: 'Ticket transfer failed.',
  //           description:
  //             'The user might already have this pass. If not, contact Wave Support.',
  //           status: 'error',
  //           duration: 9000,
  //           isClosable: true,
  //           position: 'bottom-right',
  //         });
  //         setTicketTransferLoading(false);
  //       }
  //     })
  //     .catch(error => {
  //       toast({
  //         title: 'Ticket transfer failed.',
  //         description:
  //           'The user might already have this pass. If not, contact Wave Support.',
  //         status: 'error',
  //         duration: 9000,
  //         isClosable: true,
  //         position: 'bottom-right',
  //       });
  //       // console.log(error);
  //       setTicketTransferLoading(false);
  //     });
  //   const userUID = auth.currentUser.uid;
  //   const snap = await getDoc(doc(db, 'Users', userUID));
  //   let tempData = snap.data();

  //   tempData['passes'] = [];

  //   let newCodeStatus = new Map(codeStatus);

  //   for (var i = 0; i < snap.data()['passes'].length; i++) {
  //     if (snap.data()['passes'][i]['isActive']) {
  //       tempData['passes'].push(snap.data()['passes'][i]);
  //     }

  //     if (snap.data()['passes'][i]['codes']) {
  //       // search for this code in the database
  //       for (var j = 0; j < snap.data()['passes'][i]['codes'].length; j++) {
  //         const codesQuery = query(
  //           collection(db, 'activeCodes'),
  //           where('code', '==', snap.data()['passes'][i]['codes'][j])
  //         );
  //         const querySnapshot = await getDocs(codesQuery);
  //         if (querySnapshot.empty) {
  //           // code not found
  //           newCodeStatus.set(snap.data()['passes'][i]['codes'][j], 'Error');
  //         } else {
  //           const codeDocSnap = querySnapshot.docs[0];
  //           const codeDocData = codeDocSnap.data();
  //           if (codeDocData['active']) {
  //             newCodeStatus.set(
  //               snap.data()['passes'][i]['codes'][j],
  //               'Not Claimed'
  //             );
  //           } else {
  //             newCodeStatus.set(
  //               snap.data()['passes'][i]['codes'][j],
  //               'Claimed'
  //             );
  //           }
  //         }
  //         setCodeStatus(new Map(newCodeStatus));
  //       }
  //     }
  //   }

  //   setUserData(tempData);
  // }

  const handleSmsNumberChange = event => {
    let value = event.target.value;

    // Check if the '+1' prefix is being removed or altered
    if (!value.startsWith('+1')) {
      value = '+1' + value.replace(/[^\d]/g, '');
    }

    // Remove all non-numeric characters except leading "+"
    let cleanedValue = value.replace(/[^\d+]/g, '');

    // Handle cases where the pasted number includes '+1' leading to duplicates
    if (cleanedValue.startsWith('+1+1')) {
      cleanedValue = '+1' + cleanedValue.slice(4);
    }

    // Ensure the value always starts with '+1'
    if (!cleanedValue.startsWith('+1')) {
      cleanedValue = '+1' + cleanedValue.replace(/^\+1/, '');
    }

    // Validation for US phone numbers
    const isValid = /^\+1\d{10}$/.test(cleanedValue);

    setIsInvalid(!isValid);
    setSmsNumber(cleanedValue);
  };

  // const handleSubmit = selectedCode => {
  //   if (navigator.share) {
  //     navigator
  //       .share({
  //         title: 'Event Pass Code',
  //         text: `Here's your code for the event: ${selectedCode}`,
  //         // url: 'https://example.com/event'
  //       })
  //       .then(() => console.log('Share was successful.'))
  //       .catch(error => console.error('Sharing failed', error));
  //   } else {
  //     // Fallback: Prompt the user to share manually
  //     alert('Failed to share the code. Please share your codes manually.');
  //   }
  //   onClose(); // Close the modal
  // };

  // useEffect(() => {
  //   if (userData) {
  //     setFriends(userData['friends']);
  //   }
  // }, [userData]);

  // async function getNameFromUID(uid) {
  //   const snap = await getDoc(doc(db, 'Users', uid));
  //   if (snap.exists()) {
  //     return snap.data()['firstName'] + ' ' + snap.data()['lastName'];
  //   } else {
  //     return 'Unknown';
  //   }
  // }

  // async function getNumFromUID(uid) {
  //   const snap = await getDoc(doc(db, 'Users', uid));
  //   if (snap.exists()) {
  //     return snap.data()['phoneNumber'];
  //   } else {
  //     return 'Unknown';
  //   }
  // }

  const displayUser = item => {
    return userDetails[item];
  };

  // useEffect(() => {
  //   const fetchNames = async () => {
  //     const namesMap = {};
  //     if (friends && sentRequests && recvdReqs) {
  //       for (let request of sentRequests) {
  //         namesMap[request.receiver] = await getNumFromUID(request.receiver);
  //       }
  //       for (let request of recvdReqs) {
  //         namesMap[request.sender] = await getNameFromUID(request.sender);
  //       }
  //       for (let friend of friends) {
  //         namesMap[friend] = await getNameFromUID(friend);
  //       }
  //       setNames(namesMap);
  //     }
  //   };

  //   fetchNames();
  // }, [sentRequests, recvdReqs, friends]);

  useEffect(() => {
    const goHome = () => navigate('/');

    const handleAuthChange = async user => {
      if (user) {
        setLoggedIn(true);
        setLoading(false);
        const userUID = user.uid;

        const snap = await getDoc(doc(db, 'Users', userUID));
        if (
          !snap.data()['birthdate'] ||
          !snap.data()['firstName'] ||
          !snap.data()['lastName']
        ) {
          signOutFunction();
        }

        let tempData = snap.data();
        setUserData(tempData);
      } else {
        setLoggedIn(false);
        setLoading(false);
        goHome();
      }
    };

    const unsubscribe = auth.onAuthStateChanged(handleAuthChange);

    document.body.style.background = '#141416';
    return () => {
      unsubscribe();
    };
  }, [
    // friendRequestsChanged,
    navigate,
    refreshTrigger,
  ]);

  // useEffect(() => {
  //   const doThing = async () => {
  //     if (loggedIn) {
  //       const userUID = auth.currentUser.uid;

  //       const sentReqsQuery = query(
  //         collection(db, 'friendRequests'),
  //         where('sender', '==', userUID),
  //         where('status', '==', 'pending')
  //       );
  //       const sentReqs = await getDocs(sentReqsQuery);
  //       setSentRequests(sentReqs.docs.map(doc => doc.data()));

  //       const recvdReqsQuery = query(
  //         collection(db, 'friendRequests'),
  //         where('receiver', '==', userUID),
  //         where('status', '==', 'pending')
  //       );
  //       const recvdReqs = await getDocs(recvdReqsQuery);

  //       // const snap = await getDoc(doc(db, 'Users', userUID));
  //       // Include the doc ID of each request
  //       setRecvdRequests(
  //         recvdReqs.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  //       );
  //     }
  //   };

  //   doThing();
  // }, [userData, loggedIn]);

  // function friendAction(request, approve) {
  //   setLoadingStates({ ...loadingStates, [request.id]: true });
  //   const actionStr = approve ? 'approve' : 'deny';
  //   const functions = getFunctions();
  //   const friendRequestAction = httpsCallable(functions, 'friendRequestAction');
  //   friendRequestAction({ docID: request.id, action: actionStr })
  //     .then(result => {
  //       // Read result of the Cloud Function.
  //       /** @type {any} */
  //       const data = result.data;
  //       if (data.status === 200) {
  //         if (data.message === 'Friend request denied') {
  //           toast({
  //             title: 'Friend request denied successfully!',
  //             status: 'success',
  //             duration: 9000,
  //             isClosable: true,
  //             position: 'bottom-right',
  //           });
  //         } else {
  //           toast({
  //             title: 'Friend request approved successfully!',
  //             status: 'success',
  //             duration: 9000,
  //             isClosable: true,
  //             position: 'bottom-right',
  //           });
  //         }
  //         // Trigger the useEffect to re-fetch the data
  //         setFriendRequestsChanged(prev => !prev);
  //       } else {
  //         toast({
  //           title: 'Friend request action failed.',
  //           description: 'If the problem persists, contact support.',
  //           status: 'error',
  //           duration: 9000,
  //           isClosable: true,
  //           position: 'bottom-right',
  //         });
  //         setLoadingStates(prev => ({ ...prev, [request.id]: false }));
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       toast({
  //         title: 'Friend request action failed.',
  //         description: 'If the problem persists, contact support.',
  //         status: 'error',
  //         duration: 9000,
  //         isClosable: true,
  //         position: 'bottom-right',
  //       });
  //       setLoadingStates(prev => ({ ...prev, [request.id]: false }));
  //     });
  // }

  // function handleFriends() {
  //   // once submitted, change modal to loading state
  //   setRequestingFriend(true);
  //   // then call firebase function
  //   if (smsNumber === userData['phoneNumber']) {
  //     toast({
  //       title: 'Friend request action failed.',
  //       description: 'Unable to request yourself as a friend.',
  //       status: 'error',
  //       duration: 9000,
  //       isClosable: true,
  //       position: 'bottom-right',
  //     });
  //     setRequestingFriend(false);
  //     return;
  //   }
  //   const functions = getFunctions();
  //   const friendRequest = httpsCallable(functions, 'friendRequest');
  //   friendRequest({ receiver: smsNumber, message: 'Join me on Wave!' })
  //     .then(result => {
  //       // Read result of the Cloud Function.
  //       /** @type {any} */
  //       const data = result.data;

  //       if (data.status === 200) {
  //         toast({
  //           title: 'Friend request sent successfully!',
  //           // description: 'We hope you enjoy your Wave Product!',
  //           status: 'success',
  //           duration: 9000,
  //           isClosable: true,
  //           position: 'bottom-right',
  //         });

  //         onFriendsClose();
  //         setRequestingFriend(false);
  //       } else if (data.status === 400) {
  //         toast({
  //           title: 'Code redemption failed.',
  //           description: 'Friend request failed! Please try again.',
  //           status: 'error',
  //           duration: 9000,
  //           isClosable: true,
  //           position: 'bottom-right',
  //         });
  //         setRequestingFriend(false);
  //       } else {
  //         toast({
  //           title: 'Code redemption failed.',
  //           description:
  //             'Friend request failed! Unknown error. If the problem persists, contact Wave Support.',
  //           status: 'error',
  //           duration: 9000,
  //           isClosable: true,
  //           position: 'bottom-right',
  //         });
  //         setRequestingFriend(false);
  //       }
  //       // if (data === 'Success') {
  //       //   toast({
  //       //     title: 'Code redemption successful!.',
  //       //     description: 'We hope you enjoy your Wave Product!',
  //       //     status: 'success',
  //       //     duration: 9000,
  //       //     isClosable: true,
  //       //     position: 'bottom-right',
  //       //   });
  //       // } else {
  //       //   toast({
  //       //     title: 'Code redemption failed.',
  //       //     description:
  //       //       'Double check your code and try again. If the problem persists, contact Wave Support.',
  //       //     status: 'error',
  //       //     duration: 9000,
  //       //     isClosable: true,
  //       //     position: 'bottom-right',
  //       //   });
  //       // }
  //     })
  //     .catch(error => {
  //       // toast({
  //       //   title: 'Code redemption failed.',
  //       //   description:
  //       //     'Double check your code and try again. If the problem persists, contact Wave Support.',
  //       //   status: 'error',
  //       //   duration: 9000,
  //       //   isClosable: true,
  //       //   position: 'bottom-right',
  //       // });
  //       console.log(error);
  //     });

  //   // on success close modal
  //   // otherwise keep it open
  // }

  function formatPhoneNumber(phoneNumber) {
    const match = phoneNumber.match(/^\+1(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
    } else {
      return phoneNumber; // Return the original if it doesn't match the expected format
    }
  }

  function convertBirthdate(birthdate) {
    const [year, month, day] = birthdate.split('-');
    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    const getPassDetailedData = async () => {
      setTableLoading(true);
      let tempData = userData;
      tempData['passes'] = userData['passes'].filter(p => {
        const eventEndTime = p['eventEndTime'];
        const isActive = p['isActive'];
        return eventEndTime && eventEndTime.toDate() > new Date() && isActive;
      });

      let newCodeStatus = new Map(prevCodeStatusRef.current);
      let newPhoneNums = new Map();
      for (let pass of userData['passes']) {
        if (pass['codes']) {
          for (let code of pass['codes']) {
            const codesQuery = query(
              collection(db, 'activeCodes'),
              where('code', '==', code)
            );
            const pendingQuery = query(
              collection(db, 'pendingTickets'),
              where('code', '==', code)
            );
            const querySnapshot = await getDocs(codesQuery);
            const pendingSnapshot = await getDocs(pendingQuery);
            if (querySnapshot.empty) {
              newCodeStatus.set(code, 'Error');
            } else {
              const codeDocData = querySnapshot.docs[0].data();
              var pendingTicket = false;
              if (!pendingSnapshot.empty) {
                pendingTicket = true;
              }

              if (codeDocData['active'] && !pendingTicket) {
                newCodeStatus.set(code, 'Not Claimed');
                newPhoneNums.set(code, 'N/A');
              } else if (codeDocData['active'] && pendingTicket) {
                newCodeStatus.set(code, 'Pending');
                newPhoneNums.set(
                  code,
                  pendingSnapshot.docs[0].data()['receiver']
                );
              } else {
                newCodeStatus.set(code, 'Claimed');
                newPhoneNums.set(code, 'Transferred successfully');
              }
            }
          }
        }

        setCodeStatus(new Map(newCodeStatus));
        setUserDetails(Object.fromEntries(newPhoneNums));
        prevCodeStatusRef.current = newCodeStatus;
        setTableLoading(false);
      }
    };
    if (userData.passes) {
      getPassDetailedData();
    }
  }, [userData]);

  useEffect(() => {
    let dateTimes = [];
    if (userData.passes) {
      for (let i = 0; i < userData.passes.length; i++) {
        if (
          userData.passes[i].eventTime &&
          userData.passes[i].eventTime.seconds
        ) {
          const eventTimeSeconds = userData.passes[i].eventTime.seconds;
          const date = new Date(0); // Create a new date object with timestamp 0 (epoch)
          date.setUTCSeconds(eventTimeSeconds);

          // Options for toLocaleString to get the desired format
          const options = {
            weekday: 'short', // "Sat"
            year: 'numeric', // "2024"
            month: 'short', // "Mar"
            day: 'numeric', // "16"
            hour: 'numeric', // "12 AM/PM"
            minute: '2-digit', // "00"
            hour12: true, // 12hr format
          };

          // Convert to local string with specified options
          const dateString = date.toLocaleString('en-US', options);

          // Replace the comma after the time with "pm" or "am"
          const formattedDateString = dateString
            .replace(/,\s(?=\d{1,2}:\d{2}\s(?:AM|PM))/, '')
            .replace(/(\d{4})/, '$1 ');
          dateTimes.push(formattedDateString);
        }
      }
      setEventTime(dateTimes);
    }
  }, [userData]);

  return (
    <ChakraProvider theme={ButtonTheme}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh', // Container takes at least full viewport height
        }}
      >
        {isDesktop && (
          <div>
            <div
              style={{
                position: 'absolute',
                top: '80px',
                left: '200px',
                transform: 'translate(-50%, -50%)',
                zIndex: 10000,
              }}
            >
              <a href="/">
                <img
                  style={{ width: 300, height: 100 }}
                  src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                  alt="wavelogo"
                />
              </a>
            </div>
            <Image
              src="/blurred_background.png"
              style={{
                position: 'fixed', // Changed to 'fixed' to cover the whole page
                top: 0,
                left: 0,
                width: '100%',
                minHeight: '100vh', // Ensure it covers at least the viewport height
                objectFit: 'cover',
                zIndex: -3,
              }}
            />
            <div
              id="content-container"
              style={{
                position: 'relative',
                top: '50px', // Changed to a fixed value from the top
                paddingBottom: '80px',
                width: '80%',
                left: '50%',
                transform: 'translateX(-50%)', // Only translate horizontally
                zIndex: 2,
                textAlign: 'center',
                color: '#fff',
              }}
            >
              <div style={{ height: '120px' }} />
              <HStack spacing={4} w="full" align="flex-start">
                <VStack spacing={4} w="30%">
                  <Box
                    bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                    p={4} // padding
                    borderRadius="lg" // rounded corners
                    width="full"
                  >
                    {auth.currentUser && userData.birthdate ? (
                      <>
                        <FormControl id="first-last-name" mb={4}>
                          <FormLabel color="black">Name</FormLabel>
                          <Box p={2} bg="white" borderRadius="md" color="black">
                            {userData['firstName']} {userData['lastName']}
                          </Box>
                        </FormControl>

                        <FormControl id="email" mb={4}>
                          <FormLabel color="black">Email</FormLabel>
                          <Box p={2} bg="white" borderRadius="md" color="black">
                            {userData['email']}
                          </Box>
                        </FormControl>

                        <FormControl id="birthdate" mb={4}>
                          <FormLabel color="black">Birthdate</FormLabel>
                          <Box p={2} bg="white" borderRadius="md" color="black">
                            {convertBirthdate(userData['birthdate'])}
                          </Box>
                        </FormControl>

                        <FormControl id="phone-number">
                          <FormLabel color="black">Phone Number</FormLabel>
                          <Box p={2} bg="white" borderRadius="md" color="black">
                            {formatPhoneNumber(auth.currentUser.phoneNumber)}
                          </Box>
                        </FormControl>
                      </>
                    ) : (
                      <Box display="flex" justifyContent="center" width="100%">
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                        />
                      </Box>
                    )}
                  </Box>

                  {/* <Box
                    bg="rgba(211, 211, 211, 0.5)"
                    p={4}
                    borderRadius="lg"
                    w="full"
                  >
                    My Friends <br />
                    <Box display="flex" justifyContent="center" mb={4}>
                      <Button
                        onClick={() => setView('friends')}
                        isActive={view === 'friends'}
                        style={{
                          backgroundColor: view === 'friends' ? 'blue' : '',
                          color: view === 'friends' ? 'white' : '',
                        }}
                      >
                        Friends
                      </Button>
                      <div style={{ width: 10 }} />
                      <Button
                        onClick={() => setView('incoming')}
                        isActive={view === 'incoming'}
                        style={{
                          backgroundColor: view === 'incoming' ? 'blue' : '',
                          color: view === 'incoming' ? 'white' : '',
                        }}
                      >
                        Requests
                      </Button>
                      <div style={{ width: 10 }} />
                      <Button
                        onClick={() => setView('sent')}
                        isActive={view === 'sent'}
                        style={{
                          backgroundColor: view === 'sent' ? 'blue' : '',
                          color: view === 'sent' ? 'white' : '',
                        }}
                      >
                        Sent Requests
                      </Button>
                    </Box>
                    {view === 'friends' && (
                      <>
                        {userData['friends'] &&
                          userData['friends'].map(friend => (
                            <Box
                              key={friend}
                              p={4}
                              bg="lightgrey"
                              boxShadow="md"
                              borderRadius="md"
                              mb={4}
                            >
                              <Heading size="md" color="black">
                                {names[friend] || 'Loading...'}
                              </Heading>
                            </Box>
                          ))}
                        {userData['friends'] &&
                          userData['friends'].length === 0 && (
                            <Text>No friends added yet!</Text>
                          )}
                      </>
                    )}
                    {view === 'incoming' && (
                      <>
                        {recvdReqs.map(request => (
                          <Box key={request.sender}>
                            {names[request.sender] || 'Loading...'}{' '}
                            <Button
                              onClick={() => friendAction(request, true)}
                              size="xs"
                              isLoading={loadingStates[request.id] === true} // Add isLoading prop
                            >
                              Approve
                            </Button>{' '}
                            <Button
                              onClick={() => friendAction(request, false)}
                              size="xs"
                              isLoading={loadingStates[request.id] === true} // Add isLoading prop
                            >
                              Deny
                            </Button>
                          </Box>
                        ))}
                        {recvdReqs.length === 0 && (
                          <Text>No requests received yet!</Text>
                        )}
                      </>
                    )}
                    {view === 'sent' && (
                      <>
                        {sentRequests.map(request => (
                          <Box key={request.receiver}>
                            {names[request.receiver] || 'Loading...'}
                          </Box>
                        ))}
                        {sentRequests.length === 0 && (
                          <Text>No requests sent yet!</Text>
                        )}
                      </>
                    )}
                    <div style={{ height: 15 }} />
                    <Button onClick={onFriendsOpen}>Add Friends</Button>
                  </Box>
                  <div style={{ height: '20px' }} /> */}

                  {/* <div style={{ height: '20px' }} /> */}
                </VStack>
                <VStack spacing={4} w="full">
                  <HStack spacing={4} w="full">
                    <Box
                      bg="rgba(211, 211, 211, 0.5)"
                      p={4}
                      borderRadius="lg"
                      height="250px" // Fixed height
                      w="full"
                      display="flex" // Add flex display
                      alignItems="center" // Center vertically
                      justifyContent="center" // Center horizontally
                      textAlign="center" // Center text
                    >
                      <VStack>
                        <Text>
                          Welcome to Wave! With Wave, your ID is your ticket. No
                          resellers and no fake tickets means a safer night out
                          for everyone. Have a code? Redeem it below.
                        </Text>
                        <Button
                          variant="solid"
                          onClick={goToRedeem}
                          marginTop="20px"
                        >
                          Redeem Code
                        </Button>
                      </VStack>
                    </Box>
                    <Box
                      bg="rgba(211, 211, 211, 0.5)"
                      p={4}
                      borderRadius="lg"
                      height="250px" // Fixed height
                      w="full"
                      display="flex" // Add flex display
                      alignItems="center" // Center vertically
                      justifyContent="center" // Center horizontally
                      textAlign="center" // Center text
                    >
                      <VStack>
                        <Text>
                          The best live shows, parties, and VIP experiences -
                          all on one app. Catch the Wave today. Coming soon to
                          iOS and Android.
                        </Text>
                        <Button
                          marginTop="20px"
                          variant="solid"
                          onClick={() => (window.location.href = '/')}
                          isDisabled
                        >
                          Coming Soon
                        </Button>
                      </VStack>
                    </Box>
                  </HStack>
                  <Box
                    flex={1}
                    bg="rgba(211, 211, 211, 0.5)"
                    p={4}
                    borderRadius="lg"
                    w="100%"
                  >
                    <VStack spacing={4} align="stretch" justifyContent="center">
                      <Text
                        textAlign="center"
                        fontWeight="bold"
                        fontSize="20px"
                        color="white"
                      >
                        Your Active Passes
                      </Text>
                      {(!userData['passes'] ||
                        userData === undefined ||
                        userData.passes === undefined) && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          width="100%"
                        >
                          <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                          />
                        </Box>
                      )}
                      {userData['passes'] &&
                        userData['passes'].map(
                          (pass, index) =>
                            pass['isActive'] &&
                            eventTime && (
                              <HStack
                                key={index}
                                align="center"
                                justifyContent="center"
                                spacing={4}
                              >
                                <Image
                                  src={pass['passImage']}
                                  w="35%"
                                  borderRadius="md"
                                />
                                <VStack align="stretch">
                                  <Text
                                    fontWeight="bold"
                                    fontSize="lg"
                                    color="white"
                                    textAlign="center"
                                  >
                                    {pass['passName']} on {eventTime[index]}
                                  </Text>
                                  {tableLoading ? (
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      width="100%"
                                    >
                                      <Spinner
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                        size="xl"
                                      />
                                    </Box>
                                  ) : (
                                    <Table variant="simple" size="sm" w="100%">
                                      <Thead>
                                        <Tr>
                                          <Th color="white">Ticket</Th>
                                          <Th color="white">Guest</Th>
                                          <Th color="white">Status</Th>
                                        </Tr>
                                      </Thead>
                                      <Tbody>
                                        <Tr>
                                          <Td color="white">My Ticket</Td>
                                          <Td color="white"></Td>
                                          <Td>
                                            <Badge bg="green.500" color="white">
                                              Claimed
                                            </Badge>
                                          </Td>
                                        </Tr>
                                        {pass['codes'] &&
                                          pass['codes'].length > 0 &&
                                          pass['codes'].map((code, idx) => {
                                            const status = codeStatus.get(code);
                                            let badgeColor = 'gray';
                                            if (status === 'Claimed') {
                                              badgeColor = 'green.500'; // Intense green color
                                            } else if (
                                              status === 'Not Claimed'
                                            ) {
                                              badgeColor = 'red.500'; // Intense red color
                                            } else if (status === 'Error') {
                                              badgeColor = 'orange.500'; // Intense orange color
                                            }
                                            return (
                                              <Tr key={idx}>
                                                <Td color="white">
                                                  {status === 'Pending' && (
                                                    <Button
                                                      size="xs"
                                                      colorScheme="blue"
                                                      onClick={() =>
                                                        openCancelTransferModal(
                                                          code,
                                                          displayUser(code)
                                                        )
                                                      }
                                                    >
                                                      Cancel Transfer
                                                    </Button>
                                                  )}
                                                </Td>
                                                <Td color="white">
                                                  {status === 'Not Claimed' && (
                                                    <Button
                                                      size="xs"
                                                      colorScheme="blue"
                                                      onClick={() =>
                                                        handleInviteClick(code)
                                                      }
                                                    >
                                                      Invite
                                                    </Button>
                                                  )}
                                                  {status === 'Pending' && (
                                                    <span>
                                                      {displayUser(code) ? (
                                                        <p>
                                                          {formatPhoneNumber(
                                                            displayUser(code)
                                                          )}
                                                        </p>
                                                      ) : (
                                                        <Text>Loading...</Text>
                                                      )}
                                                    </span>
                                                  )}
                                                  {status === 'Claimed' && (
                                                    <span>
                                                      {displayUser(code) ? (
                                                        <p>
                                                          {displayUser(code)}
                                                        </p>
                                                      ) : (
                                                        <Text>Loading...</Text>
                                                      )}
                                                    </span>
                                                  )}
                                                </Td>
                                                <Td>
                                                  <Badge
                                                    bg={badgeColor}
                                                    color="white"
                                                  >
                                                    {status}
                                                  </Badge>
                                                </Td>
                                              </Tr>
                                            );
                                          })}
                                      </Tbody>
                                    </Table>
                                  )}
                                </VStack>
                              </HStack>
                            )
                        )}
                      {userData['passes'] &&
                        userData['passes'].length === 0 && (
                          <Text color="white">You have no active passes.</Text>
                        )}
                    </VStack>
                  </Box>
                </VStack>
              </HStack>

              <div style={{ height: '20px' }} />

              <Box>
                <Box height={10}></Box>
                <Button onClick={signOutFunction}>Sign Out</Button>
              </Box>
              {/* <Box>
                <Button onClick={testFunction1}>Test APIs</Button>
              </Box> */}

              {/* <Center>
                <div className="App">
                  {loading ? (
                    <div style={{ color: 'white' }}>Loading...</div>
                  ) : (
                    loggedIn && (
                      <div>
                        <h1
                          style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: 40,
                            color: 'white',
                          }}
                        >
                          Welcome back, {userData['firstName']}{' '}
                          {userData['lastName']}!
                        </h1>
                        <div></div>
                        <br />

                        <Center>
                          <br />
                          <br />
                          <br />
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Button variant="solid" onClick={goHome}>
                              Explore Events
                            </Button>
                            <div style={{ width: 30 }}></div>
                            <Button onClick={signOutFunction}>Sign Out</Button>
                          </div>
                        </Center>
                      </div>
                    )
                  )}
                </div>
              </Center> */}
            </div>
          </div>
        )}
        {/* {isTablet && ()} */}
        {(isTablet || isMobile) && (
          <div>
            <div style={{ height: 90 }}></div>
            <div
              style={{
                position: 'absolute',
                top: 50,
                left: 90,
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
              }}
            >
              <a href="/">
                <img
                  style={{ width: 160, height: 50 }}
                  src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                  alt="wavelogo"
                />
              </a>
            </div>
            <Image
              src="/blurred_background.png"
              style={{
                position: 'fixed', // Changed to 'fixed' to cover the whole page
                top: 0,
                left: 0,
                width: '100%',
                minHeight: '100vh', // Ensure it covers at least the viewport height
                objectFit: 'cover',
                zIndex: -1,
              }}
            />
            <div
              id="content-container"
              style={{
                position: 'relative',
                // top: '30px', // Changed to a fixed value from the top
                // paddingBottom: '40px',
                // width: '50%',
                left: '50%',
                transform: 'translateX(-50%)', // Only translate horizontally
                zIndex: 1,
                textAlign: 'center',
                color: '#fff',
              }}
            >
              <Center>
                <div className="App" style={{ width: '90%' }}>
                  {loading ? (
                    <Spinner
                      justifyContent="center"
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  ) : (
                    loggedIn && (
                      <div>
                        <div style={{ height: '20px' }} />
                        {auth.currentUser && userData.birthdate && (
                          <Box
                            bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                            p={4} // padding
                            borderRadius="lg" // rounded corners
                          >
                            <FormControl id="first-last-name" mb={4}>
                              <FormLabel color="black">Name</FormLabel>
                              <Box
                                p={2}
                                bg="white"
                                borderRadius="md"
                                color="black"
                              >
                                {userData['firstName']} {userData['lastName']}
                              </Box>
                            </FormControl>

                            <FormControl id="email" mb={4}>
                              <FormLabel color="black">Email</FormLabel>
                              <Box
                                p={2}
                                bg="white"
                                borderRadius="md"
                                color="black"
                              >
                                {userData['email']}
                              </Box>
                            </FormControl>

                            <FormControl id="birthdate" mb={4}>
                              <FormLabel color="black">Birthdate</FormLabel>
                              <Box
                                p={2}
                                bg="white"
                                borderRadius="md"
                                color="black"
                              >
                                {convertBirthdate(userData['birthdate'])}
                              </Box>
                            </FormControl>

                            <FormControl id="phone-number">
                              <FormLabel color="black">Phone Number</FormLabel>
                              <Box
                                p={2}
                                bg="white"
                                borderRadius="md"
                                color="black"
                              >
                                {formatPhoneNumber(
                                  auth.currentUser.phoneNumber
                                )}
                              </Box>
                            </FormControl>
                          </Box>
                        )}
                        <div style={{ height: '20px' }} />
                        <HStack spacing={4} w="full">
                          <Box
                            flex={1}
                            bg="rgba(211, 211, 211, 0.5)"
                            p={4}
                            borderRadius="lg"
                          >
                            <VStack
                              spacing={4}
                              align="stretch"
                              justifyContent="center"
                            >
                              <Text
                                textAlign="center"
                                fontWeight="bold"
                                fontSize="20px"
                                color="white"
                              >
                                Your Active Passes
                              </Text>
                              {(!userData['passes'] ||
                                userData === undefined ||
                                userData.passes === undefined) && (
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  width="100%"
                                >
                                  <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                  />
                                </Box>
                              )}
                              {userData['passes'] &&
                                eventTime &&
                                userData['passes'].map((pass, index) => {
                                  const codes = pass['codes'] || []; // Retrieve codes array for this pass
                                  const combinedItems = [pass, ...codes]; // Combine pass with its codes

                                  return (
                                    <React.Fragment key={index}>
                                      {combinedItems.map((item, idx) => {
                                        // Determine the border color based on claim status
                                        const borderColor =
                                          idx === 0 ||
                                          codeStatus.get(item) === 'Claimed'
                                            ? 'green.500'
                                            : codeStatus.get(item) ===
                                                  'Not Claimed' ||
                                                codeStatus.get(item) ===
                                                  'Pending'
                                              ? 'red.500'
                                              : 'gray.500';

                                        return (
                                          <VStack
                                            key={`${index}-${idx}`}
                                            align="center"
                                            justifyContent="center"
                                            spacing={4}
                                            border="4px solid" // Add a border
                                            borderColor={borderColor} // Dynamic border color
                                            borderRadius="md" // Rounded edges
                                            padding="4" // Add padding inside the border
                                          >
                                            <Image
                                              cursor={
                                                idx === 0 && pass['QR_code']
                                                  ? 'pointer'
                                                  : 'default'
                                              }
                                              onClick={
                                                idx === 0 && pass['QR_code']
                                                  ? () =>
                                                      openQRModal(
                                                        pass['QR_code']
                                                      )
                                                  : undefined
                                              }
                                              src={pass['passImage']}
                                              w="65%"
                                              borderRadius="md"
                                            />
                                            <VStack align="stretch">
                                              <Text
                                                fontWeight="bold"
                                                fontSize="lg"
                                                color="white"
                                                textAlign="center"
                                              >
                                                {idx === 0
                                                  ? `My Ticket: ${pass['passName']} on ${eventTime[index]}`
                                                  : `Ticket ${idx}: ${pass['passName']} on ${eventTime[index]}`}
                                              </Text>
                                              {tableLoading ? (
                                                <Box
                                                  display="flex"
                                                  justifyContent="center"
                                                  width="100%"
                                                >
                                                  <Spinner
                                                    thickness="4px"
                                                    speed="0.65s"
                                                    emptyColor="gray.200"
                                                    color="blue.500"
                                                    size="xl"
                                                  />
                                                </Box>
                                              ) : (
                                                <Table
                                                  variant="simple"
                                                  size="sm"
                                                >
                                                  <Thead>
                                                    <Tr>
                                                      <Th color="white">
                                                        Ticket
                                                      </Th>
                                                      <Th color="white">
                                                        Guest
                                                      </Th>
                                                      <Th color="white">
                                                        Status
                                                      </Th>
                                                    </Tr>
                                                  </Thead>
                                                  <Tbody>
                                                    <Tr>
                                                      <Td color="white">
                                                        {idx === 0 ? (
                                                          'My Ticket'
                                                        ) : (
                                                          <>
                                                            {codeStatus.get(
                                                              item
                                                            ) === 'Pending' && (
                                                              <Button
                                                                size="xs"
                                                                colorScheme="blue"
                                                                onClick={() =>
                                                                  openCancelTransferModal(
                                                                    item,
                                                                    displayUser(
                                                                      item
                                                                    )
                                                                  )
                                                                }
                                                              >
                                                                Cancel Transfer
                                                              </Button>
                                                            )}
                                                          </>
                                                        )}
                                                      </Td>
                                                      <Td color="white">
                                                        {idx > 0 &&
                                                          codeStatus.get(
                                                            item
                                                          ) ===
                                                            'Not Claimed' && (
                                                            <Button
                                                              size="xs"
                                                              colorScheme="blue"
                                                              onClick={() =>
                                                                handleInviteClick(
                                                                  item
                                                                )
                                                              }
                                                            >
                                                              Invite
                                                            </Button>
                                                          )}
                                                        {idx > 0 &&
                                                          codeStatus.get(
                                                            item
                                                          ) === 'Pending' && (
                                                            <span>
                                                              {displayUser(
                                                                item
                                                              ) ? (
                                                                <p>
                                                                  {formatPhoneNumber(
                                                                    displayUser(
                                                                      item
                                                                    )
                                                                  )}
                                                                </p>
                                                              ) : (
                                                                <Text>
                                                                  Loading...
                                                                </Text>
                                                              )}
                                                            </span>
                                                          )}
                                                        {idx > 0 &&
                                                          codeStatus.get(
                                                            item
                                                          ) === 'Claimed' && (
                                                            <span>
                                                              {displayUser(
                                                                item
                                                              ) ? (
                                                                <p>
                                                                  {displayUser(
                                                                    item
                                                                  )}
                                                                </p>
                                                              ) : (
                                                                <Text>
                                                                  Loading...
                                                                </Text>
                                                              )}
                                                            </span>
                                                          )}
                                                      </Td>
                                                      <Td>
                                                        <Badge
                                                          bg={
                                                            idx === 0
                                                              ? 'green.500'
                                                              : codeStatus.get(
                                                                    item
                                                                  ) ===
                                                                  'Claimed'
                                                                ? 'green.500'
                                                                : codeStatus.get(
                                                                      item
                                                                    ) ===
                                                                    'Not Claimed'
                                                                  ? 'red.500'
                                                                  : codeStatus.get(
                                                                        item
                                                                      ) ===
                                                                      'Error'
                                                                    ? 'orange.500'
                                                                    : 'gray'
                                                          }
                                                          color="white"
                                                        >
                                                          {idx === 0
                                                            ? 'Claimed'
                                                            : codeStatus.get(
                                                                item
                                                              )}
                                                        </Badge>
                                                      </Td>
                                                    </Tr>
                                                  </Tbody>
                                                </Table>
                                              )}
                                            </VStack>
                                            {idx > 0 ? (
                                              <>
                                                {codeStatus.get(item) ===
                                                  'Not Claimed' && (
                                                  <Box
                                                    style={{
                                                      background:
                                                        'linear-gradient(to right, #008cff, #ff008c 80%)',
                                                      WebkitBackgroundClip:
                                                        'padding-box',
                                                      color: 'white',
                                                      fontWeight: 'bold',
                                                      padding: '8px 16px',
                                                      borderRadius: '8px',
                                                      textAlign: 'center',
                                                    }}
                                                  >
                                                    You must send your guest
                                                    this ticket!
                                                  </Box>
                                                )}
                                                {codeStatus.get(item) ===
                                                  'Pending' && (
                                                  <Box
                                                    bg="red.500"
                                                    color="white"
                                                    px="4"
                                                    py="2"
                                                    borderRadius="md"
                                                    textAlign="center"
                                                  >
                                                    Your guest hasn't claimed
                                                    their ticket! They must make
                                                    an account to claim their
                                                    ticket.
                                                  </Box>
                                                )}
                                              </>
                                            ) : (
                                              <Box
                                                bg="gray.500"
                                                color="white"
                                                px="4"
                                                py="2"
                                                borderRadius="md"
                                                textAlign="center"
                                              >
                                                This is your ticket!
                                              </Box>
                                            )}
                                          </VStack>
                                        );
                                      })}
                                    </React.Fragment>
                                  );
                                })}

                              {userData['passes'] &&
                                userData['passes'].length === 0 && (
                                  <Text color="white">
                                    You have no active passes.
                                  </Text>
                                )}
                            </VStack>
                          </Box>
                        </HStack>
                        <div style={{ height: '20px' }} />
                        <Box
                          bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                          p={4} // padding
                          borderRadius="lg" // rounded corners
                        >
                          Welcome to Wave! With Wave, your ID is your ticket. No
                          resellers and no fake tickets means a safer night out
                          for everyone. <br />
                          Have a code? Redeem it below. <br />
                          <Button variant="solid" onClick={goToRedeem}>
                            Redeem Code
                          </Button>
                        </Box>

                        <div style={{ height: '20px' }} />

                        {/* <Box
                          bg="rgba(211, 211, 211, 0.5)"
                          p={4}
                          borderRadius="lg"
                          w="100%"
                        >
                          My Friends <br />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={4}
                          >
                            <Button
                              onClick={() => setView('friends')}
                              isActive={view === 'friends'}
                              style={{
                                backgroundColor:
                                  view === 'friends' ? 'blue' : '',
                                color: view === 'friends' ? 'white' : '',
                              }}
                            >
                              Friends
                            </Button>
                            <Button
                              onClick={() => setView('incoming')}
                              isActive={view === 'incoming'}
                              style={{
                                backgroundColor:
                                  view === 'incoming' ? 'blue' : '',
                                color: view === 'incoming' ? 'white' : '',
                              }}
                            >
                              Requests
                            </Button>
                            <Button
                              onClick={() => setView('sent')}
                              isActive={view === 'sent'}
                              style={{
                                backgroundColor: view === 'sent' ? 'blue' : '',
                                color: view === 'sent' ? 'white' : '',
                              }}
                            >
                              Sent Requests
                            </Button>
                          </Box>
                          {view === 'friends' && (
                            <>
                              {userData['friends'] &&
                                userData['friends'].map(friend => (
                                  <Box
                                    key={friend}
                                    p={4}
                                    bg="lightgrey"
                                    boxShadow="md"
                                    borderRadius="md"
                                    mb={4}
                                  >
                                    <Heading size="md" color="black">
                                      {names[friend] || 'Loading...'}
                                    </Heading>
                                  </Box>
                                ))}
                              {userData['friends'] &&
                                userData['friends'].length === 0 && (
                                  <Text>No friends added yet!</Text>
                                )}
                            </>
                          )}
                          {view === 'incoming' && (
                            <>
                              {recvdReqs.map(request => (
                                <Box key={request.sender}>
                                  {names[request.sender] || 'Loading...'}{' '}
                                  <Button
                                    onClick={() => friendAction(request, true)}
                                    size="xs"
                                    isLoading={
                                      loadingStates[request.id] === true
                                    } // Add isLoading prop
                                  >
                                    Approve
                                  </Button>{' '}
                                  <Button
                                    onClick={() => friendAction(request, false)}
                                    size="xs"
                                    isLoading={
                                      loadingStates[request.id] === true
                                    } // Add isLoading prop
                                  >
                                    Deny
                                  </Button>
                                </Box>
                              ))}
                              {recvdReqs.length === 0 && (
                                <Text>No requests received yet!</Text>
                              )}
                            </>
                          )}
                          {view === 'sent' && (
                            <>
                              {sentRequests.map(request => (
                                <Box key={request.receiver}>
                                  {names[request.receiver] || 'Loading...'}
                                </Box>
                              ))}
                              {sentRequests.length === 0 && (
                                <Text>No requests sent yet!</Text>
                              )}
                            </>
                          )}
                          <div style={{ height: 15 }} />
                          <Button onClick={onFriendsOpen}>Add Friends</Button>
                        </Box>
                        <div style={{ height: '20px' }} /> */}
                        <Box
                          bg="rgba(211, 211, 211, 0.5)" // light grey with 50% opacity
                          p={4} // padding
                          borderRadius="lg" // rounded corners
                        >
                          The best live shows, parties, and VIP experiences -
                          all on one app. Catch the Wave today. Coming soon to
                          iOS and Android. <br />
                          {/* <HStack> */}
                          <Button
                            variant="solid"
                            // leftIcon={<FaApple />}
                            onClick={() => (window.location.href = '/')}
                            isDisabled
                          >
                            Coming Soon
                          </Button>
                        </Box>
                        <Box>
                          <Box height={10}></Box>
                          <Button onClick={signOutFunction}>Sign Out</Button>
                          <Box height={5}></Box>
                        </Box>
                      </div>
                    )
                  )}
                </div>
              </Center>
            </div>
          </div>
        )}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Invite Guest by Phone Number</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              If they don't have an account, they will automatically receive the
              ticket when they create an account with the phone number the
              ticket was sent to. <br /> <br /> If they do have an account, the
              ticket will be added to their account.
              <br />
              {/* {ticketTransferLoading && 'Loading...'} */}
              {/* {!ticketTransferLoading && (
                <Box>
                  {userData['friends'] &&
                    userData['friends'].map(friend => (
                      <Box key={friend} style={{ height: 30 }}>
                        {names[friend] || 'Loading...'}{' '}
                        <Button
                          onClick={() => sendTicket(friend, selectedCode)}
                          size="xs"
                        >
                          Send
                        </Button>
                      </Box>
                    ))}
                </Box>
              )} */}
              <br />
              <Box>
                <FormControl isInvalid={isInvalid}>
                  <FormLabel htmlFor="sms-number">
                    Enter their number here:
                  </FormLabel>
                  <Input
                    id="sms-number"
                    type="tel"
                    value={smsNumber}
                    onChange={handleSmsNumberChange}
                  />
                  {isInvalid && (
                    <FormErrorMessage>Invalid phone number.</FormErrorMessage>
                  )}
                </FormControl>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => sendTicketToNumber(smsNumber, selectedCode)}
                isDisabled={isInvalid}
                isLoading={loadingSendTicket}
              >
                Send Ticket
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* <Modal isOpen={isFriendsOpen} onClose={onFriendsClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Request your Friends</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isInvalid={isInvalid}>
                <FormLabel htmlFor="sms-number">SMS Number</FormLabel>
                <Input
                  id="sms-number"
                  type="tel"
                  value={smsNumber}
                  onChange={handleSmsNumberChange}
                />
                {isInvalid && (
                  <FormErrorMessage>Invalid phone number.</FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleFriends}
                isDisabled={isInvalid}
                isLoading={requestingFriend}
              >
                Send Invite
              </Button>
              <Button onClick={onFriendsClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal> */}

        <Modal isOpen={isQROpen} onClose={onQRClose}>
          <ModalOverlay />
          <ModalContent>
            {qrCode && <Image src={qrCode} alt="QR Code" />}
            <ModalFooter>
              <Button onClick={onQRClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isCancelPendingOpen} onClose={onCancelPendingClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Cancel Your Invite</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to cancel your ticket invite to{' '}
              {formatPhoneNumber(cancelPendingTicketRef.current.phone)}?
              <br />
              <br />
              They will no longer be able to claim the ticket, but you can
              reassign it to another number.
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() =>
                  handleCancelTransfer(
                    cancelPendingTicketRef.current.selectedCode
                  )
                }
                isLoading={loadingCancelPendingTicket}
              >
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </ChakraProvider>
  );
}

export default Profile;
