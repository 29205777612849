import React, { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import {
  ChakraProvider,
  AspectRatio,
  theme,
  Grid,
  Text,
  IconButton,
  Image,
  Link,
  Button,
  Divider,
  Center,
  Flex,
  HStack,
  VStack,
  Spacer,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FaBullhorn, FaInstagram, FaTag } from 'react-icons/fa';
import {
  collection,
  query,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db, auth } from '../firebase-config';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import ButtonTheme from '../components/Button';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { useMediaQuery } from 'react-responsive';
import { MyPaymentForm } from '../components/CheckoutDrawer';
function stripePromise() {
  return loadStripe(process.env.REACT_APP_STRIPE_KEY);
}

function EventPage() {
  const {
    isOpen: isDiscountOpen,
    onOpen: onDiscountOpen,
    onClose: onDiscountClose,
  } = useDisclosure();
  const {
    isOpen: isInfoOpen,
    onOpen: onInfoOpen,
    onClose: onInfoClose,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [data, setData] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [inThePast, setinThePast] = useState(false);
  const location = useLocation();
  const firstField = React.useRef();
  const pathArray = useMemo(
    () => location.pathname.split('/'),
    [location.pathname]
  );
  const [eventTime, setEventTime] = useState(null);
  const [eventEndTime, setEventEndTime] = useState(null);
  const [doorsOpen, setDoorsOpen] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [discountCode, setDiscountCode] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [intentPrice, setIntentPrice] = useState(0);
  const [waveFee, setWaveFee] = useState(0);
  const [taxAmt, setTaxAmt] = useState(0);
  const [buyNowButtonLoading, setBuyNowButtonLoading] = useState(false);
  const [discountButtonLoading, setDiscountButtonLoading] = useState(false);
  const [codeApplied, setCodeApplied] = useState(false);
  const [usedDiscountCode, setUsedDiscountCode] = useState('');
  const [loadingCodes, setCodesLoading] = useState(true);
  const toast = useToast();
  const [taxRate, setTaxRate] = useState(0.0975);
  const [serviceFeeRate, setServiceFeeRate] = useState(0.15);
  const [ticketCount, setTicketCount] = useState(1);
  const [ticketPackSize, setTicketPackSize] = useState(1);
  const [selectedTicket, setSelectedTicket] = useState(null);
  let navigate = useNavigate();
  const increment = () => {
    setTicketCount(ticketCount + 1);
  };
  const decrement = () => {
    if (ticketCount > 1) {
      setTicketCount(ticketCount - 1);
    }
  };
  const options = {
    clientSecret: clientSecret,
  };
  function havePass(passes, passName) {
    let i = 0;
    for (i = 0; i < passes.length; i++) {
      if (passes[i].passName === passName) {
        return true;
      }
    }
    return false;
  }

  const handleDiscountCode = async () => {
    setDiscountButtonLoading(true);
    window.onbeforeunload = function () {
      return 'Are you sure you want to leave? Your code is being processed.';
    };
    if (data.discountCodes) {
      let matched = false;
      let newPrices = [...data.prices];
      for (let i = 0; i < data.discountCodes.length; i++) {
        if (
          discountCode === data.discountCodes[i].code &&
          ((data.discountCodes[i].isActive !== undefined &&
            data.discountCodes[i].isActive) ||
            data.discountCodes[i].isActive === undefined)
        ) {
          if (
            typeof data.discountCodes[i].ticketType ===
            typeof 'stringtypeexample'
          ) {
            for (let j = 0; j < newPrices.length; j++) {
              if (newPrices[j].tickets !== undefined) {
                for (let k = 0; k < newPrices[j].tickets.length; k++) {
                  if (
                    data.discountCodes[i].ticketType === 'all' ||
                    data.discountCodes[i].ticketType ===
                      newPrices[j].tickets[k].displayType
                  ) {
                    matched = true;
                    if (data.discountCodes[i].type === 'percentage') {
                      newPrices[j].tickets[k].price =
                        newPrices[j].tickets[k].price *
                        data.discountCodes[i].discount;
                    } else if (data.discountCodes[i].type === 'fixed') {
                      newPrices[j].tickets[k].price =
                        newPrices[j].tickets[k].price -
                        data.discountCodes[i].discount;
                    }
                  }
                }
              } else {
                if (
                  data.discountCodes[i].ticketType === 'all' ||
                  data.discountCodes[i].ticketType === newPrices[j].displayType
                ) {
                  matched = true;
                  if (data.discountCodes[i].type === 'percentage') {
                    newPrices[j].price =
                      newPrices[j].price * data.discountCodes[i].discount;
                  } else if (data.discountCodes[i].type === 'fixed') {
                    newPrices[j].price =
                      newPrices[j].price - data.discountCodes[i].discount;
                  }
                }
              }
            }
          } else {
            for (let j = 0; j < newPrices.length; j++) {
              for (
                let k = 0;
                k < data.discountCodes[i].ticketType.length;
                k++
              ) {
                if (
                  data.discountCodes[i].ticketType[k] === 'all' ||
                  newPrices[j].displayType ===
                    data.discountCodes[i].ticketType[k]
                ) {
                  matched = true;
                  if (data.discountCodes[i].type === 'percentage') {
                    newPrices[j].price =
                      newPrices[j].price * data.discountCodes[i].discount;
                  } else if (data.discountCodes[i].type === 'fixed') {
                    newPrices[j].price =
                      newPrices[j].price - data.discountCodes[i].discount;
                  }
                }
              }
            }
          }
        }
      }

      if (!matched) {
        toast({
          title: 'Invalid code',
          description: 'The code you entered is not valid.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      } else {
        setData(prevData => ({ ...prevData, prices: newPrices })); // Update state with new prices
        toast({
          title: 'Code applied',
          description: 'The code you entered has been applied.',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
        setCodeApplied(true);
        setUsedDiscountCode(discountCode);
      }
      setDiscountButtonLoading(false);
      onDiscountClose();
      setDiscountCode('');
      window.onbeforeunload = null;
      return;
    }
  };

  const linkToAppStore = () => {
    const url = 'https://apps.apple.com/us/app/wave-vip/id6447489243';
    window.open(url, '_blank');
  };

  const handleSelectionChange = (value, index) => {
    // Find the selected ticket based on the selected value
    const ticket = data.prices[index].tickets.find(
      item => item.displayType === value
    );
    setSelectedTicket(ticket);
  };

  const drawerHookOnPress = async ticket => {
    setSelectedTicket(ticket);
    drawerHook(ticket.price, ticketCount, ticket.packSize, ticket);
  };

  const refreshTicket = async ticket => {
    const eventsQuery = query(
      collection(db, 'featuredEvents'),
      where('id', '==', pathArray[2])
    );
    const querySnapshot = await getDocs(eventsQuery);
    const eventDocSnap = querySnapshot.docs[0];
    const eventDocData = eventDocSnap.data();
    const eventTickets = eventDocData.prices;
    for (let i = 0; i < eventTickets.length; i++) {
      if (eventTickets[i].tickets !== undefined) {
        for (let j = 0; j < eventTickets[i].tickets.length; j++) {
          if (eventTickets[i].tickets[j].displayType === ticket.displayType) {
            return eventTickets[i].tickets[j];
          }
        }
      } else {
        if (eventTickets[i].displayType === ticket.displayType) {
          return eventTickets[i];
        }
      }
    }
  };

  // called when the "buy tickets" button is pressed
  const drawerHook = async (price, numTickets, packSize, ticketProp) => {
    if (auth.currentUser == null) {
      goToLogin();
    } else {
      const docRef = doc(db, 'Users', auth.currentUser.uid);
      const snap = await getDoc(docRef);
      let userPasses = snap.data()['passes'];

      let ticket = await refreshTicket(ticketProp);

      let realTaxRate = ticket.noTax ? 0 : taxRate;
      setTaxRate(realTaxRate);

      if (packSize > 1) {
        setTicketPackSize(packSize);
      } else {
        setTicketPackSize(1);
      }

      // main logic for buying tickets
      // first check if the user already has the pass
      if (havePass(userPasses, `${data.passName}: ${ticket.displayType}`)) {
        toast({
          title: 'You already have this pass!',
          description:
            'Go to your profile page to view your passes and tickets.',
          status: 'info',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
        closeDrawer();
        return;
      } else {
        // first - buying one ticket
        if (numTickets === 1) {
          if (
            ticket.ticketLimit <
            ticket.ticketsPurchased + numTickets * ticketPackSize
          ) {
            toast({
              title: 'Error: Not enough tickets available.',
              description:
                'Reduce the number of tickets or select a different pass type.',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'bottom-right',
            });
            return;
          }
          // if it's sold out, return an error and close
          if (ticket.soldOut) {
            toast({
              title: 'Error: This pass is sold out.',
              description:
                'Go to your profile page to view your passes and tickets.',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'bottom-right',
            });
            closeDrawer();
            return;
          }

          setBuyNowButtonLoading(true);
          if (Number(price) > 0) {
            let amount =
              Number(price) * (1 + serviceFeeRate) * (1 + realTaxRate);
            let waveFeeInstance = Number(price) * serviceFeeRate;
            let taxAmountInstance =
              Number(price) * (1 + serviceFeeRate) * realTaxRate;

            if (data.id === 'gatsbygala24') {
              if (ticket.displayType.includes('VIP')) {
                waveFeeInstance = Number(price) * 0.15 + Number(price) * 0.25;
                taxAmountInstance =
                  Number(price) * 1.15 * 0.0925 + Number(price) * 0.15;
                amount = Number(price) + waveFeeInstance + taxAmountInstance;
              } else {
                // first is 15% us, second is 12.5% staff gratuity
                waveFeeInstance =
                  Number(price) * 0.15 + Number(price) * 0.25 * 0.5;
                // first is local sales tax, second is 7.5% liquor tax
                taxAmountInstance =
                  Number(price) * 1.15 * 0.0925 + Number(price) * 0.15 * 0.5;
                amount = Number(price) + waveFeeInstance + taxAmountInstance;
              }
            }

            fetch(process.env.REACT_APP_STRIPE_ENDPOINT, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: auth.currentUser.email,
                amount: amount * numTickets,
              }),
            })
              .then(response => response.json())
              .then(res => {
                setClientSecret(res.paymentIntent);
                setIntentPrice(amount * numTickets);
                setWaveFee(waveFeeInstance * numTickets);
                setTaxAmt(taxAmountInstance * numTickets);
                onOpen();
              })
              .catch(error => {
                console.error('Error fetching client secret:', error);
              });
          } else {
            // if free
            userPasses.push({
              passName: `${data.passName}: ${ticket.displayType}`,
              datePurchased: new Date().toISOString(),
              isActive: true,
              eventTime: data.eventTime,
              eventEndTime: data.eventEndTime,
              venueId: data.venueId,
              passImage: data.eventFlyerPicture,
              ticketType: 'event',
              price: 0,
              discountCode: usedDiscountCode,
              numTickets: 1,
              taxAmount: 0,
              waveFeeAmount: 0,
              id: pathArray[2],
            });

            await updateDoc(docRef, {
              updateRequired: true,
              passes: userPasses,
            });

            toast({
              title: 'Purchase was successful!',
              description:
                'Go to your profile page to view your passes and tickets.',
              status: 'success',
              duration: 4000,
              isClosable: true,
              position: 'bottom-right',
            });
            closeDrawer();
          }
        } else {
          if (
            ticket.ticketLimit <
            ticket.ticketsPurchased + numTickets * ticketPackSize
          ) {
            toast({
              title: 'Error: Not enough tickets available.',
              description:
                'Reduce the number of tickets or select a different pass type.',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'bottom-right',
            });
            return;
          }
          setBuyNowButtonLoading(true);

          let amount = Number(price) * (1 + serviceFeeRate) * (1 + realTaxRate);
          let waveFeeInstance = Number(price) * serviceFeeRate;
          let taxAmountInstance =
            Number(price) * (1 + serviceFeeRate) * realTaxRate;

          if (data.id === 'gatsbygala24') {
            if (ticket.displayType.includes('VIP')) {
              waveFeeInstance = Number(price) * 0.15 + Number(price) * 0.25;
              taxAmountInstance =
                Number(price) * 1.15 * 0.0925 + Number(price) * 0.15;
              amount = Number(price) + waveFeeInstance + taxAmountInstance;
            } else {
              waveFeeInstance =
                Number(price) * 0.15 + Number(price) * 0.25 * 0.5;
              taxAmountInstance =
                Number(price) * 1.15 * 0.0925 + Number(price) * 0.15 * 0.5;
              amount = Number(price) + waveFeeInstance + taxAmountInstance;
            }
          }

          fetch(process.env.REACT_APP_STRIPE_ENDPOINT, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: auth.currentUser.email,
              amount: amount * numTickets,
            }),
          })
            .then(response => response.json())
            .then(res => {
              setClientSecret(res.paymentIntent);
              setIntentPrice(amount * numTickets);
              setWaveFee(waveFeeInstance * numTickets);
              setTaxAmt(taxAmountInstance * numTickets);
              onOpen();
            })
            .catch(error => {
              console.error('Error fetching client secret:', error);
            });
        }
      }
    }
  };

  const handleDirections = address => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      address
    )}`;
    window.open(url, '_blank');
  };

  const goHome = () => {
    let path = '/';
    navigate(path);
  };

  const goToCreateUser = useCallback(() => {
    navigate('/createuser');
  }, [navigate]);

  const goToNotFound = useCallback(() => {
    navigate('/not-found');
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // fetch the event data from the database based on the URL
        const fetchEvent = query(
          collection(db, 'featuredEvents'),
          where('id', '==', pathArray[2])
        );
        const eventSnapshot = await getDocs(fetchEvent);

        // if there is no event with the given id, redirect to the 404 page
        if (eventSnapshot.size === 0) {
          goToNotFound();
          return;
        }

        let fetchedData;

        // this should only happen once, eventSnapshot should only have 1 item in it. if not there
        // are 2 things with the same id in the database (bad bc only one renders on the page)
        eventSnapshot.forEach(doc => {
          fetchedData = doc.data();
        });

        if (fetchedData.diffService !== undefined) {
          setServiceFeeRate(fetchedData.diffService);
        }
        if (fetchedData.diffTax !== undefined) {
          setTaxRate(fetchedData.diffTax);
        }

        setData(fetchedData);

        if (!auth.currentUser) {
          setLoggedIn(false);
          return;
        } else {
          setLoggedIn(true);
          const userUID = auth.currentUser.uid;
          const userDataQuery = await getDoc(doc(db, 'Users', userUID));

          if (
            userDataQuery.data()['birthdate'] === '' &&
            userDataQuery.data()['firstName'] === '' &&
            userDataQuery.data()['lastName'] === ''
          ) {
            goToCreateUser();
          }
        }
      } catch (error) {
        console.error('Error fetching data from the database:', error);
      }
    };

    document.body.style.background = '#141416';
    fetchData();
  }, [pathArray, goToCreateUser, goToNotFound]);

  // useEffect hook to translate firebase date objects to something we can display
  useEffect(() => {
    if (
      data.eventTime &&
      data.eventTime.seconds &&
      data.eventEndTime &&
      data.eventEndTime.seconds
    ) {
      const eventTimeSeconds = data.eventTime.seconds;
      const date = new Date(0); // Create a new date object with timestamp 0 (epoch)
      date.setUTCSeconds(eventTimeSeconds);
      setEventTime(date);

      const eventEndTimeSeconds = data.eventEndTime.seconds;
      const eventEndTimedate = new Date(0); // Create a new date object with timestamp 0 (epoch)
      eventEndTimedate.setUTCSeconds(eventEndTimeSeconds);
      setEventEndTime(eventEndTimedate);

      const doorsOpenSeconds = data.doorsOpen.seconds;
      const doorsOpenDate = new Date(0); // Create a new date object with timestamp 0 (epoch)
      doorsOpenDate.setUTCSeconds(doorsOpenSeconds);
      setDoorsOpen(doorsOpenDate);
    }
    if (data.prices) {
      const sortedPrices = [...data.prices].sort((a, b) => {
        // If both have "order", sort numerically by "order"
        if (a.order !== undefined && b.order !== undefined) {
          return a.order - b.order;
        }
        // If only one has "order", it comes first
        if (a.order !== undefined) return -1;
        if (b.order !== undefined) return 1;
        // If neither has "order", preserve their original order
        return 0;
      });

      data.prices = sortedPrices;
    }
  }, [data]);

  // useEffect hook to set the past variable to true
  useEffect(() => {
    if (eventEndTime && moment(eventEndTime) < moment()) {
      setinThePast(true);
    }
  }, [eventEndTime]);

  // helper function to turn time into a 12 hour time
  function to12HrTime(time) {
    let hrs = time.slice(0, 2);
    if (hrs > 12) {
      return hrs - 12 + ':' + time.slice(3, 5) + ' PM';
    } else if (hrs === '12') {
      return '12:00 PM';
    } else if (hrs === '00') {
      return '12:00 AM';
    } else {
      return hrs + ':' + time.slice(3, 5) + ' AM';
    }
  }

  function goToLogin() {
    navigate(`/login?redirect=${pathArray[2]}`);
  }

  function goToProfile() {
    navigate('/profile');
  }

  function closeDrawer() {
    onClose();
    setBuyNowButtonLoading(false);
  }

  return (
    <>
      <ChakraProvider theme={ButtonTheme}>
        {(isTablet || isMobile) && (
          <div>
            <img
              src={data.eventFlyerPicture}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -2,
                filter: 'blur(20px) brightness(80%)',
                objectFit: 'cover',
              }}
              alt="BlurryImage"
              className="blur-image"
            />
            <VStack>
              <div style={{ height: 90 }}></div>
              <div
                style={{
                  position: 'absolute',
                  top: 25,
                  left: 20,
                  zIndex: 2,
                  width: '90%',
                }}
              >
                <a href="/">
                  <img
                    style={{ width: 160, height: 50, zIndex: 2 }}
                    src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                    alt="wavelogo"
                  />
                </a>
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 50,
                  right: 20,
                  transform: 'translate(0%, -50%)',
                  zIndex: 3,
                }}
              >
                {loggedIn ? (
                  <Button variant="solid" onClick={goToProfile}>
                    My Tickets
                  </Button>
                ) : (
                  <>
                    <Button variant="solid" onClick={goToLogin}>
                      Login or Signup
                    </Button>
                  </>
                )}
              </div>

              <Center>
                <Box
                  bg="rgba(0, 0, 0, 0.5)" // Transparent black background
                  borderRadius="15px" // Rounded corners
                  p="4" // Padding
                  color="#fff" // Text color
                  width="90%"
                >
                  <VStack>
                    <div
                      className="App"
                      style={{
                        width: '90%',
                      }}
                    >
                      {data.eventFlyerPicture && (
                        <VStack>
                          <Image
                            boxSize="250"
                            src={data.eventFlyerPicture}
                            style={{ borderRadius: 25 }}
                          />
                          <Box w={240}>
                            <Text
                              textColor="white"
                              fontSize={14}
                              textAlign={'center'}
                            >
                              With Wave, your ID is your ticket. No resellers
                              and no fake tickets means a safer night out for
                              everyone.
                            </Text>
                          </Box>
                        </VStack>
                      )}
                      <div style={{}}>
                        <p
                          style={{
                            color: 'white',
                            fontSize: '50px',
                            padding: 0,
                            lineHeight: '1.3', // Adjust the value as needed
                          }}
                        >
                          {data.eventName}
                        </p>
                        <Text textColor="white" fontSize="25">
                          {data.venueName}
                        </Text>
                        <div style={{ height: 10 }} />
                        {eventTime && (
                          <Text textColor="white">
                            {to12HrTime(eventTime.toTimeString())}
                            {', '}
                            {eventTime.toDateString()}
                          </Text>
                        )}
                        <div style={{ height: 10 }} />
                        <Text color="white" display="flex" alignItems="center">
                          <Image
                            src="/tag-icon.png"
                            boxSize="5"
                            marginRight="0.5rem"
                            alt="Tag Icon"
                          />

                          {data.tags && data.tags.length > 0 ? (
                            data.tags.map((item, index) => (
                              <React.Fragment key={index}>
                                {index !== 0 && ', '}
                                {item}
                              </React.Fragment>
                            ))
                          ) : (
                            <React.Fragment>None</React.Fragment>
                          )}
                          <div style={{ width: 10 }} />
                          <Text
                            textColor="white"
                            display="flex"
                            alignItems="center"
                          >
                            <Image
                              src="/location-icon.png"
                              boxSize="5"
                              marginRight="0.5rem"
                            ></Image>
                            {data.location}
                          </Text>
                        </Text>

                        <div style={{ height: 15 }}></div>
                        <Accordion defaultIndex={[0]}>
                          {data.prices &&
                            data.prices.map((item, index) => (
                              <>
                                {(item.isActive === undefined ||
                                  item.isActive) && (
                                  <AccordionItem key={index}>
                                    <div>
                                      <h2>
                                        <AccordionButton>
                                          {item.tickets !== undefined ? (
                                            <Box
                                              as="span"
                                              flex="1"
                                              textAlign="left"
                                              color="white"
                                            >
                                              {item.type}: Multiple Options
                                            </Box>
                                          ) : (
                                            <>
                                              {item.price === '0' ? (
                                                <Box
                                                  as="span"
                                                  flex="1"
                                                  textAlign="left"
                                                  color="white"
                                                >
                                                  {item.type}: Free
                                                </Box>
                                              ) : (
                                                <Box
                                                  as="span"
                                                  flex="1"
                                                  textAlign="left"
                                                  color="white"
                                                >
                                                  {item.type}: $
                                                  {(item.price / 100).toFixed(
                                                    2
                                                  )}
                                                </Box>
                                              )}
                                            </>
                                          )}
                                          <AccordionIcon color={'white'} />
                                        </AccordionButton>
                                      </h2>
                                      <AccordionPanel pb={4}>
                                        {item.desc && (
                                          <Text>
                                            {item.desc}
                                            <br />
                                            <br />
                                          </Text>
                                        )}
                                        <>
                                          {inThePast ? (
                                            <Button
                                              isDisabled
                                              onClick={() => {}}
                                              variant="solid"
                                            >
                                              Event has Passed
                                            </Button>
                                          ) : (
                                            <>
                                              {item.soldOut ? (
                                                <Button
                                                  isDisabled
                                                  onClick={() => {}}
                                                  variant="solid"
                                                >
                                                  Sold Out
                                                </Button>
                                              ) : (
                                                <>
                                                  {item.price === '0' ? (
                                                    <Button
                                                      onClick={() =>
                                                        drawerHookOnPress(item)
                                                      }
                                                      variant="solid"
                                                      isLoading={
                                                        buyNowButtonLoading
                                                      }
                                                    >
                                                      RSVP Now
                                                    </Button>
                                                  ) : (
                                                    <>
                                                      {item.packSize !==
                                                      undefined ? (
                                                        <HStack>
                                                          <Button
                                                            onClick={() =>
                                                              drawerHookOnPress(
                                                                item
                                                              )
                                                            }
                                                            variant="solid"
                                                            isLoading={
                                                              buyNowButtonLoading
                                                            }
                                                          >
                                                            Buy Now
                                                          </Button>
                                                          <Button
                                                            onClick={decrement}
                                                            variant="outline"
                                                            color="white"
                                                            size="sm"
                                                            disabled={
                                                              buyNowButtonLoading
                                                            }
                                                          >
                                                            -
                                                          </Button>
                                                          <div
                                                            style={{
                                                              fontSize: 20,
                                                            }}
                                                          >
                                                            {ticketCount}
                                                          </div>
                                                          <Button
                                                            onClick={increment}
                                                            variant="outline"
                                                            color="white"
                                                            size="sm"
                                                            disabled={
                                                              buyNowButtonLoading
                                                            }
                                                          >
                                                            +
                                                          </Button>
                                                        </HStack>
                                                      ) : (
                                                        <>
                                                          {item.tickets !==
                                                          undefined ? (
                                                            <>
                                                              {item.image && (
                                                                <div>
                                                                  <Text>
                                                                    {
                                                                      item.imageHeader
                                                                    }
                                                                  </Text>
                                                                  <Image
                                                                    src={
                                                                      item.image
                                                                    }
                                                                  />
                                                                  <div
                                                                    style={{
                                                                      height: 10,
                                                                    }}
                                                                  />
                                                                </div>
                                                              )}
                                                              {item.tickets.some(
                                                                displayType =>
                                                                  displayType.displayType ===
                                                                  selectedTicket?.displayType
                                                              ) && (
                                                                <Text textColor="white">
                                                                  {
                                                                    item.tickets.find(
                                                                      displayType =>
                                                                        displayType.displayType ===
                                                                        selectedTicket?.displayType
                                                                    )?.desc
                                                                  }
                                                                </Text>
                                                              )}

                                                              <Select
                                                                onChange={event =>
                                                                  handleSelectionChange(
                                                                    event.target
                                                                      .value,
                                                                    index
                                                                  )
                                                                }
                                                              >
                                                                <option
                                                                  value=""
                                                                  disabled
                                                                  selected
                                                                >
                                                                  {' '}
                                                                  Select a
                                                                  ticket type{' '}
                                                                </option>
                                                                {item.tickets.map(
                                                                  (
                                                                    itemSpec,
                                                                    indexSpec
                                                                  ) => (
                                                                    <>
                                                                      {(itemSpec.isActive ===
                                                                        undefined ||
                                                                        itemSpec.isActive) && (
                                                                        <option
                                                                          key={
                                                                            indexSpec
                                                                          }
                                                                          value={
                                                                            itemSpec.displayType
                                                                          }
                                                                          disabled={
                                                                            itemSpec.soldOut
                                                                            //  || inThePast
                                                                          }
                                                                        >
                                                                          {
                                                                            itemSpec.type
                                                                          }{' '}
                                                                          - $
                                                                          {itemSpec.price /
                                                                            100}
                                                                        </option>
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </Select>
                                                              <HStack
                                                                style={{
                                                                  paddingTop: 10,
                                                                }}
                                                              >
                                                                <Button
                                                                  onClick={() =>
                                                                    drawerHookOnPress(
                                                                      selectedTicket
                                                                    )
                                                                  }
                                                                  variant="solid"
                                                                  isLoading={
                                                                    buyNowButtonLoading
                                                                  }
                                                                  isDisabled={
                                                                    !item.tickets.some(
                                                                      displayType =>
                                                                        displayType.displayType ===
                                                                        selectedTicket?.displayType
                                                                    )
                                                                  }
                                                                >
                                                                  Buy Now
                                                                </Button>
                                                                <Button
                                                                  onClick={
                                                                    decrement
                                                                  }
                                                                  variant="outline"
                                                                  color="white"
                                                                  size="sm"
                                                                  disabled={
                                                                    buyNowButtonLoading
                                                                  }
                                                                >
                                                                  -
                                                                </Button>
                                                                <div
                                                                  style={{
                                                                    fontSize: 20,
                                                                  }}
                                                                >
                                                                  {ticketCount}
                                                                </div>
                                                                <Button
                                                                  onClick={
                                                                    increment
                                                                  }
                                                                  variant="outline"
                                                                  color="white"
                                                                  size="sm"
                                                                  disabled={
                                                                    buyNowButtonLoading
                                                                  }
                                                                >
                                                                  +
                                                                </Button>
                                                              </HStack>
                                                            </>
                                                          ) : (
                                                            <HStack>
                                                              <Button
                                                                onClick={() =>
                                                                  drawerHookOnPress(
                                                                    item
                                                                  )
                                                                }
                                                                variant="solid"
                                                                isLoading={
                                                                  buyNowButtonLoading
                                                                }
                                                              >
                                                                Buy Now
                                                              </Button>
                                                              <Button
                                                                onClick={
                                                                  decrement
                                                                }
                                                                variant="outline"
                                                                color="white"
                                                                size="sm"
                                                                disabled={
                                                                  buyNowButtonLoading
                                                                }
                                                              >
                                                                -
                                                              </Button>
                                                              <div
                                                                style={{
                                                                  fontSize: 20,
                                                                }}
                                                              >
                                                                {ticketCount}
                                                              </div>
                                                              <Button
                                                                onClick={
                                                                  increment
                                                                }
                                                                variant="outline"
                                                                color="white"
                                                                size="sm"
                                                                disabled={
                                                                  buyNowButtonLoading
                                                                }
                                                              >
                                                                +
                                                              </Button>
                                                            </HStack>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      </AccordionPanel>
                                    </div>
                                  </AccordionItem>
                                )}
                              </>
                            ))}
                        </Accordion>

                        {/*  <div style={{ height: 10 }}></div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Button onClick={RedeemRoute} variant="solid">
                            Redeem a Ticket Code
                          </Button>
                        </div> */}
                        <div style={{ height: 10 }}></div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {codeApplied ? (
                            <Button
                              onClick={onDiscountOpen}
                              isDisabled={true}
                              variant="solid"
                            >
                              Discount Code Applied
                            </Button>
                          ) : (
                            <Button
                              onClick={onDiscountOpen}
                              isDisabled={false}
                              variant="solid"
                            >
                              Enter a Discount Code
                            </Button>
                          )}
                        </div>

                        <div style={{ height: 10 }}></div>
                        <Divider />
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <Text textColor="white" fontSize="25">
                            About
                          </Text>
                          <div style={{ height: 10 }} />
                          <Text textColor="white">
                            {data.ageLine1}{' '}
                            <Link
                              color="teal.500"
                              href={data.linkLine1}
                              isExternal
                            >
                              {data.linkLine1}
                            </Link>
                          </Text>
                          <br />
                          {data.contentLine2 && (
                            <Text textColor="white">
                              {data.contentLine2}{' '}
                              <Link
                                color="teal.500"
                                href={data.linkLine2}
                                isExternal
                              >
                                {data.linkLine2.slice(7)}
                              </Link>
                            </Text>
                          )}
                          {data.line2 && (
                            <>
                              <br />
                              <Text textColor="white">{data.line2}</Text>
                            </>
                          )}
                          <br />
                          <Text textColor="white">{data.line3}</Text>
                          <br />
                          <Text textColor="white">
                            {data.line4}{' '}
                            <Link
                              color="teal.500"
                              href={data.linkLine4}
                              isExternal
                            >
                              {data.linkLine4}
                            </Link>
                          </Text>
                          <br />
                          <Text textColor="white">
                            <Link
                              color="teal.500"
                              href="https://www.wavenightlife.com/terms-conditions"
                              isExternal
                            >
                              Terms and Conditions
                            </Link>{' '}
                            apply.
                          </Text>
                          <br />
                          <HStack>
                            <FaBullhorn style={{ color: 'white' }}></FaBullhorn>
                            <Text textColor="white">{data.line5}</Text>
                          </HStack>
                          <div style={{ height: 10 }} />
                        </div>
                        <Divider />
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <Text textColor="white" fontSize="25">
                            Lineup
                          </Text>
                          <div style={{ height: 10 }} />
                          {data.lineup &&
                            data.lineup.map((item, index) => (
                              <div key={index}>
                                <Flex
                                  direction="row"
                                  align="center"
                                  justify="space-between"
                                >
                                  <Flex align="center">
                                    <Image
                                      src={item.image}
                                      boxSize="65"
                                      alt="Your Image"
                                      borderRadius="full"
                                    />
                                    <Text
                                      textColor="white"
                                      marginLeft="1rem"
                                      fontWeight="bold"
                                    >
                                      {item.name}
                                    </Text>
                                  </Flex>

                                  <Spacer />

                                  <IconButton
                                    as={Link}
                                    href={item.instaLink}
                                    aria-label="Instagram"
                                    icon={
                                      <FaInstagram
                                        style={{ fontSize: '24px' }}
                                      />
                                    }
                                  />

                                  <div style={{ width: 20 }} />
                                </Flex>
                                <div style={{ height: 10 }} />
                              </div>
                            ))}
                        </div>

                        <Divider />
                        {(data.venueMap ||
                          data.venueVideo ||
                          data.venuePics) && (
                          <div>
                            <Divider />
                            <div
                              style={{
                                paddingTop: 10,
                                paddingBottom: 30,
                              }}
                            >
                              <Text textColor="white" fontSize="25">
                                Take a Look
                              </Text>
                              <div style={{ height: 10 }} />
                              {/* video here */}
                              {data.venueVideo && (
                                <Box>
                                  <AspectRatio ratio={16 / 9}>
                                    <video controls>
                                      <source
                                        src={data.venueVideo}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </AspectRatio>
                                </Box>
                              )}
                              {data.venueMap && (
                                <Box>
                                  <div style={{ height: 10 }} />
                                  <Image src={data.venueMap}></Image>
                                </Box>
                              )}
                              {data.venuePics && (
                                <Box>
                                  <div style={{ height: 20 }} />
                                  <Grid
                                    templateColumns="repeat(2, 1fr)"
                                    gap={6}
                                  >
                                    {data.venuePics.map((image, index) => (
                                      <Image
                                        key={index}
                                        src={image}
                                        alt={`image-${index}`}
                                        boxSize="100%"
                                        objectFit="cover"
                                      />
                                    ))}
                                  </Grid>
                                </Box>
                              )}
                            </div>
                          </div>
                        )}
                        {data.sponsors && (
                          <div>
                            <Divider />
                            <div
                              style={{
                                paddingTop: 10,
                                paddingBottom: 30,
                                paddingRight: 15,
                              }}
                            >
                              <Text textColor="white" fontSize="25">
                                Sponsors
                              </Text>
                              <div style={{ height: 10 }} />
                              {data.sponsors && (
                                <div
                                  style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                    gap: '20px',
                                    justifyContent: 'center', // This helps center the last item if there's an odd number
                                  }}
                                >
                                  {data.sponsors.map((item, index) => (
                                    <div
                                      key={index} // Assuming 'item' is a unique value, you can use it as a key instead: key={item}
                                      style={{
                                        height: '60px', // Ensure the items take full width of the grid column
                                        display: 'flex',
                                        justifyContent: 'center', // Center the image within the grid column
                                      }}
                                    >
                                      <Image
                                        src={item}
                                        style={{
                                          maxWidth: '100%',
                                          height: 'auto',
                                        }} // Ensure the image scales with the column width and maintain its aspect ratio
                                      ></Image>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <Divider />
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <Text textColor="white" fontSize="25">
                            Venue
                          </Text>
                          <Text textColor="white" fontSize="35">
                            {data.venueName}
                          </Text>
                          <Text textColor="white">{data.address}</Text>
                          <br></br>
                          {data.address && (
                            <Button
                              onClick={() => handleDirections(data.address)}
                              leftIcon={
                                <HiOutlineLocationMarker
                                  style={{ height: 50 }}
                                />
                              }
                            >
                              Open in Maps
                            </Button>
                          )}
                          <br />
                          <br />
                          {doorsOpen && (
                            <Text textColor="white">
                              Doors open: {to12HrTime(doorsOpen.toTimeString())}
                              {' on '}
                              {doorsOpen.toDateString()}
                            </Text>
                          )}
                        </div>
                        <Divider />
                        <HStack>
                          <Box w={400}>
                            <div
                              style={{
                                paddingTop: 10,
                                color: 'white',
                                fontSize: 25,
                              }}
                            >
                              Download the Wave App
                            </div>
                            <div style={{ height: 10 }} />
                            <Text color="white">
                              The best live shows, parties, and VIP experiences
                              - all on one app. Catch the Wave today.
                            </Text>
                            <div style={{ height: 25 }} />
                            <HStack>
                              <Button
                                variant="solid"
                                // leftIcon={<FaApple />}
                                onClick={linkToAppStore}
                                isDisabled
                              >
                                Coming Soon
                                {/* iOS */}
                              </Button>
                              {/* <Button
                              variant="solid"
                              leftIcon={<AiFillAndroid />}
                              onClick={() =>
                                alert(
                                  'This will redirect to the android upon release'
                                )
                              }
                            >
                              Android
                            </Button> */}
                            </HStack>
                          </Box>
                        </HStack>
                      </div>
                    </div>
                  </VStack>
                </Box>
              </Center>
            </VStack>
          </div>
        )}

        {isDesktop && (
          <div>
            <img
              src={data.eventFlyerPicture}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -2,
                filter: 'blur(20px) brightness(80%)',
                objectFit: 'cover',
              }}
              alt="BlurryImage"
              className="blur-image"
            />
            <VStack>
              <div style={{ height: 150 }}></div>
              <div
                style={{
                  position: 'absolute',
                  top: '80px',
                  left: '200px',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 2,
                }}
              >
                <a href="/">
                  <img
                    style={{ width: 300, height: 100 }}
                    src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                    alt="wavelogo"
                  />
                </a>
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '80px',
                  right: '50px',
                  transform: 'translate(0%, -50%)',
                  zIndex: 1,
                }}
              >
                {loggedIn ? (
                  <Button variant="solid" onClick={goToProfile}>
                    My Tickets
                  </Button>
                ) : (
                  <>
                    <Button variant="solid" onClick={goToLogin}>
                      Login or Signup
                    </Button>
                  </>
                )}
              </div>

              <Center>
                <Flex direction="row" alignItems="flex-start">
                  <IconButton
                    icon={<ArrowBackIcon />}
                    onClick={goHome}
                    isRound
                    fontSize="24px"
                  />
                  <div style={{ width: 40 }} />
                  <Box
                    bg="rgba(0, 0, 0, 0.5)" // Transparent black background
                    borderRadius="15px" // Rounded corners
                    p="4" // Padding
                    color="#fff" // Text color
                  >
                    <Flex direction="row" alignItems="flex-start">
                      <HStack spacing={50}>
                        {data.eventFlyerPicture && (
                          <VStack>
                            <Image
                              boxSize="250"
                              src={data.eventFlyerPicture}
                              style={{ borderRadius: 25 }}
                            />
                            <Box w={240}>
                              <Text
                                textColor="white"
                                fontSize={14}
                                textAlign={'center'}
                              >
                                With Wave, your ID is your ticket. No resellers
                                and no fake tickets means a safer night out for
                                everyone.
                              </Text>
                            </Box>
                          </VStack>
                        )}
                        <div />
                      </HStack>
                      <HStack>
                        <div
                          className="App"
                          style={{
                            width: 700,
                          }}
                        >
                          <div style={{}}>
                            <p
                              style={{
                                color: 'white',
                                fontSize: '50px',
                                padding: 0,
                                lineHeight: '1.3', // Adjust the value as needed
                              }}
                            >
                              {data.eventName}
                            </p>
                            <Text textColor="white" fontSize="25">
                              {data.venueName}
                            </Text>
                            <div style={{ height: 10 }} />
                            {eventTime && (
                              <Text textColor="white">
                                {to12HrTime(eventTime.toTimeString())}
                                {', '}
                                {eventTime.toDateString()}
                              </Text>
                            )}
                            <div style={{ height: 10 }} />
                            <Text
                              color="white"
                              display="flex"
                              alignItems="center"
                            >
                              <Image
                                src="/tag-icon.png"
                                boxSize="5"
                                marginRight="0.5rem"
                                alt="Tag Icon"
                              />

                              {data.tags && data.tags.length > 0 ? (
                                data.tags.map((item, index) => (
                                  <React.Fragment key={index}>
                                    {index !== 0 && ', '}
                                    {item}
                                  </React.Fragment>
                                ))
                              ) : (
                                <React.Fragment>None</React.Fragment>
                              )}
                              <div style={{ width: 10 }} />
                              <Text
                                textColor="white"
                                display="flex"
                                alignItems="center"
                              >
                                <Image
                                  src="/location-icon.png"
                                  boxSize="5"
                                  marginRight="0.5rem"
                                ></Image>
                                {data.location}
                              </Text>
                            </Text>

                            <div style={{ height: 15 }}></div>
                            <Accordion defaultIndex={[0]}>
                              {data.prices &&
                                data.prices.map((item, index) => (
                                  <>
                                    {(item.isActive === undefined ||
                                      item.isActive) && (
                                      <AccordionItem key={index}>
                                        <div>
                                          <h2>
                                            <AccordionButton>
                                              {item.tickets !== undefined ? (
                                                <Box
                                                  as="span"
                                                  flex="1"
                                                  textAlign="left"
                                                  color="white"
                                                >
                                                  {item.type}: Multiple Options
                                                </Box>
                                              ) : (
                                                <>
                                                  {item.price === '0' ? (
                                                    <Box
                                                      as="span"
                                                      flex="1"
                                                      textAlign="left"
                                                      color="white"
                                                    >
                                                      {item.type}: Free
                                                    </Box>
                                                  ) : (
                                                    <Box
                                                      as="span"
                                                      flex="1"
                                                      textAlign="left"
                                                      color="white"
                                                    >
                                                      {item.type}: $
                                                      {(
                                                        item.price / 100
                                                      ).toFixed(2)}
                                                    </Box>
                                                  )}
                                                </>
                                              )}

                                              <AccordionIcon color={'white'} />
                                            </AccordionButton>
                                          </h2>
                                          <AccordionPanel pb={4}>
                                            {item.desc && (
                                              <Text>
                                                {item.desc}
                                                <br />
                                                <br />
                                              </Text>
                                            )}
                                            <>
                                              {inThePast ? (
                                                <Button
                                                  isDisabled
                                                  onClick={() => {}}
                                                  variant="solid"
                                                >
                                                  Event has Passed
                                                </Button>
                                              ) : (
                                                <>
                                                  {item.soldOut ? (
                                                    <Button
                                                      isDisabled
                                                      onClick={() => {}}
                                                      variant="solid"
                                                    >
                                                      Sold Out
                                                    </Button>
                                                  ) : (
                                                    <>
                                                      {item.price === '0' ? (
                                                        <Button
                                                          onClick={() =>
                                                            drawerHookOnPress(
                                                              item
                                                            )
                                                          }
                                                          variant="solid"
                                                          isLoading={
                                                            buyNowButtonLoading
                                                          }
                                                        >
                                                          RSVP Now
                                                        </Button>
                                                      ) : (
                                                        <>
                                                          {item.packSize !==
                                                          undefined ? (
                                                            <HStack>
                                                              <Button
                                                                onClick={() =>
                                                                  drawerHookOnPress(
                                                                    item
                                                                  )
                                                                }
                                                                variant="solid"
                                                                isLoading={
                                                                  buyNowButtonLoading
                                                                }
                                                              >
                                                                Buy Now
                                                              </Button>
                                                              <Button
                                                                onClick={
                                                                  decrement
                                                                }
                                                                variant="outline"
                                                                color="white"
                                                                size="sm"
                                                                disabled={
                                                                  buyNowButtonLoading
                                                                }
                                                              >
                                                                -
                                                              </Button>
                                                              <div
                                                                style={{
                                                                  fontSize: 20,
                                                                }}
                                                              >
                                                                {ticketCount}
                                                              </div>
                                                              <Button
                                                                onClick={
                                                                  increment
                                                                }
                                                                variant="outline"
                                                                color="white"
                                                                size="sm"
                                                                disabled={
                                                                  buyNowButtonLoading
                                                                }
                                                              >
                                                                +
                                                              </Button>
                                                            </HStack>
                                                          ) : (
                                                            <>
                                                              {item.tickets !==
                                                              undefined ? (
                                                                <>
                                                                  {item.image && (
                                                                    <div>
                                                                      <Text>
                                                                        {
                                                                          item.imageHeader
                                                                        }
                                                                      </Text>
                                                                      <Image
                                                                        src={
                                                                          item.image
                                                                        }
                                                                      />
                                                                      <div
                                                                        style={{
                                                                          height: 10,
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  )}

                                                                  {item.tickets.some(
                                                                    displayType =>
                                                                      displayType.displayType ===
                                                                      selectedTicket?.displayType
                                                                  ) && (
                                                                    <Text
                                                                      textColor="white"
                                                                      style={{
                                                                        paddingBottom: 10,
                                                                      }}
                                                                    >
                                                                      {
                                                                        item.tickets.find(
                                                                          displayType =>
                                                                            displayType.displayType ===
                                                                            selectedTicket?.displayType
                                                                        )?.desc
                                                                      }
                                                                    </Text>
                                                                  )}
                                                                  <Select
                                                                    onChange={event =>
                                                                      handleSelectionChange(
                                                                        event
                                                                          .target
                                                                          .value,
                                                                        index
                                                                      )
                                                                    }
                                                                  >
                                                                    <option
                                                                      value=""
                                                                      disabled
                                                                      selected
                                                                    >
                                                                      {' '}
                                                                      Select a
                                                                      ticket
                                                                      type{' '}
                                                                    </option>
                                                                    {item.tickets.map(
                                                                      (
                                                                        itemSpec,
                                                                        indexSpec
                                                                      ) => (
                                                                        <>
                                                                          {(itemSpec.isActive ===
                                                                            undefined ||
                                                                            itemSpec.isActive) && (
                                                                            <option
                                                                              key={
                                                                                indexSpec
                                                                              }
                                                                              value={
                                                                                itemSpec.displayType
                                                                              }
                                                                              disabled={
                                                                                itemSpec.soldOut
                                                                                //  || inThePast
                                                                              }
                                                                            >
                                                                              {
                                                                                itemSpec.type
                                                                              }{' '}
                                                                              -
                                                                              $
                                                                              {itemSpec.price /
                                                                                100}
                                                                            </option>
                                                                          )}
                                                                        </>
                                                                      )
                                                                    )}
                                                                  </Select>
                                                                  <HStack
                                                                    style={{
                                                                      paddingTop: 10,
                                                                    }}
                                                                  >
                                                                    <Button
                                                                      onClick={() =>
                                                                        drawerHookOnPress(
                                                                          selectedTicket
                                                                        )
                                                                      }
                                                                      variant="solid"
                                                                      isLoading={
                                                                        buyNowButtonLoading
                                                                      }
                                                                      isDisabled={
                                                                        !item.tickets.some(
                                                                          displayType =>
                                                                            displayType.displayType ===
                                                                            selectedTicket?.displayType
                                                                        )
                                                                      }
                                                                    >
                                                                      Buy Now
                                                                    </Button>
                                                                    <Button
                                                                      onClick={
                                                                        decrement
                                                                      }
                                                                      variant="outline"
                                                                      color="white"
                                                                      size="sm"
                                                                      disabled={
                                                                        buyNowButtonLoading
                                                                      }
                                                                    >
                                                                      -
                                                                    </Button>
                                                                    <div
                                                                      style={{
                                                                        fontSize: 20,
                                                                      }}
                                                                    >
                                                                      {
                                                                        ticketCount
                                                                      }
                                                                    </div>
                                                                    <Button
                                                                      onClick={
                                                                        increment
                                                                      }
                                                                      variant="outline"
                                                                      color="white"
                                                                      size="sm"
                                                                      disabled={
                                                                        buyNowButtonLoading
                                                                      }
                                                                    >
                                                                      +
                                                                    </Button>
                                                                  </HStack>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <HStack>
                                                                    <Button
                                                                      onClick={() =>
                                                                        drawerHookOnPress(
                                                                          item
                                                                        )
                                                                      }
                                                                      variant="solid"
                                                                      isLoading={
                                                                        buyNowButtonLoading
                                                                      }
                                                                    >
                                                                      Buy Now
                                                                    </Button>
                                                                    <Button
                                                                      onClick={
                                                                        decrement
                                                                      }
                                                                      variant="outline"
                                                                      color="white"
                                                                      size="sm"
                                                                      disabled={
                                                                        buyNowButtonLoading
                                                                      }
                                                                    >
                                                                      -
                                                                    </Button>
                                                                    <div
                                                                      style={{
                                                                        fontSize: 20,
                                                                      }}
                                                                    >
                                                                      {
                                                                        ticketCount
                                                                      }
                                                                    </div>
                                                                    <Button
                                                                      onClick={
                                                                        increment
                                                                      }
                                                                      variant="outline"
                                                                      color="white"
                                                                      size="sm"
                                                                      disabled={
                                                                        buyNowButtonLoading
                                                                      }
                                                                    >
                                                                      +
                                                                    </Button>
                                                                  </HStack>
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          </AccordionPanel>
                                        </div>
                                      </AccordionItem>
                                    )}
                                  </>
                                ))}
                            </Accordion>
                            <div style={{ height: 10 }}></div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // height: '200px', // for example, the parent div height
                              }}
                            >
                              {codeApplied ? (
                                <Button
                                  onClick={onDiscountOpen}
                                  isDisabled={true}
                                  variant="solid"
                                >
                                  Discount Code Applied
                                </Button>
                              ) : (
                                <Button
                                  onClick={onDiscountOpen}
                                  isDisabled={false}
                                  variant="solid"
                                >
                                  Enter a Discount Code
                                </Button>
                              )}
                            </div>

                            <div style={{ height: 10 }}></div>
                            <Divider />
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <Text textColor="white" fontSize="25">
                                About
                              </Text>
                              <div style={{ height: 10 }} />
                              <Text textColor="white">
                                {data.ageLine1}{' '}
                                <Link
                                  color="teal.500"
                                  href={data.linkLine1}
                                  isExternal
                                >
                                  {data.linkLine1}
                                </Link>
                              </Text>
                              <br />
                              {data.contentLine2 && (
                                <Text textColor="white">
                                  {data.contentLine2}{' '}
                                  <Link
                                    color="teal.500"
                                    href={data.linkLine2}
                                    isExternal
                                  >
                                    {data.linkLine2.slice(7)}
                                  </Link>
                                </Text>
                              )}
                              {data.line2 && (
                                <>
                                  <br />
                                  <Text textColor="white">{data.line2}</Text>
                                </>
                              )}
                              <br />
                              <Text textColor="white">{data.line3}</Text>
                              <br />
                              <Text textColor="white">
                                {data.line4}{' '}
                                <Link
                                  color="teal.500"
                                  href={data.linkLine4}
                                  isExternal
                                >
                                  {data.linkLine4}
                                </Link>
                              </Text>
                              <br />
                              <Text textColor="white">
                                <Link
                                  color="teal.500"
                                  href="https://www.wavenightlife.com/terms-conditions"
                                  isExternal
                                >
                                  Terms and Conditions
                                </Link>{' '}
                                apply.
                              </Text>
                              <br />
                              <HStack>
                                <FaBullhorn
                                  style={{ color: 'white' }}
                                ></FaBullhorn>
                                <Text textColor="white">{data.line5}</Text>
                              </HStack>
                              <div style={{ height: 10 }} />
                            </div>
                            <Divider />
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <Text textColor="white" fontSize="25">
                                Lineup
                              </Text>
                              <div style={{ height: 10 }} />
                              {data.lineup &&
                                data.lineup.map((item, index) => (
                                  <div key={index}>
                                    <Flex
                                      direction="row"
                                      align="center"
                                      justify="space-between"
                                    >
                                      <Flex align="center">
                                        <Image
                                          src={item.image}
                                          boxSize="70"
                                          alt="Your Image"
                                          borderRadius="full" // Adjust `100px` as needed for your desired height.

                                          // objectFit="cover" // If you are okay with cropping.
                                        />
                                        <Text
                                          textColor="white"
                                          marginLeft="1rem"
                                          fontWeight="bold"
                                          fontSize="25"
                                        >
                                          {item.name}
                                        </Text>
                                      </Flex>

                                      <Spacer />

                                      <IconButton
                                        as={Link}
                                        href={item.instaLink}
                                        aria-label="Instagram"
                                        icon={
                                          <FaInstagram
                                            style={{ fontSize: '24px' }}
                                          />
                                        } // Adjust the fontSize as needed
                                      />

                                      <div style={{ width: 20 }} />
                                    </Flex>
                                    <div style={{ height: 10 }} />
                                  </div>
                                ))}
                            </div>
                            {(data.venueMap ||
                              data.venueVideo ||
                              data.venuePics) && (
                              <div>
                                <Divider />
                                <div
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 30,
                                  }}
                                >
                                  <Text textColor="white" fontSize="25">
                                    Take a Look
                                  </Text>
                                  <div style={{ height: 10 }} />
                                  {/* video here */}
                                  {data.venueVideo && (
                                    <Box>
                                      <AspectRatio ratio={16 / 9}>
                                        <video controls>
                                          <source
                                            src={data.venueVideo}
                                            type="video/mp4"
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video>
                                      </AspectRatio>
                                    </Box>
                                  )}
                                  {data.venueMap && (
                                    <Box>
                                      <div style={{ height: 10 }} />
                                      <Image src={data.venueMap}></Image>
                                    </Box>
                                  )}
                                  {/* gallery here */}
                                  {data.venuePics && (
                                    <Box>
                                      <div style={{ height: 20 }} />
                                      <Grid
                                        templateColumns="repeat(2, 1fr)"
                                        gap={6}
                                      >
                                        {data.venuePics.map((image, index) => (
                                          <Image
                                            key={index}
                                            src={image}
                                            alt={`image-${index}`}
                                            boxSize="100%"
                                            objectFit="cover"
                                          />
                                        ))}
                                      </Grid>
                                    </Box>
                                  )}
                                </div>
                              </div>
                            )}
                            {data.sponsors && (
                              <div>
                                <Divider />
                                <div
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 30,
                                    paddingRight: 15,
                                  }}
                                >
                                  <Text textColor="white" fontSize="25">
                                    Sponsors
                                  </Text>
                                  <div style={{ height: 10 }} />
                                  {data.sponsors && (
                                    <div
                                      style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                        gap: '30px',
                                        justifyContent: 'center', // This helps center the last item if there's an odd number
                                      }}
                                    >
                                      {data.sponsors.map((item, index) => (
                                        <div
                                          key={index} // Assuming 'item' is a unique value, you can use it as a key instead: key={item}
                                          style={{
                                            height: '100px', // Ensure the items take full width of the grid column
                                            display: 'flex',
                                            justifyContent: 'center', // Center the image within the grid column
                                          }}
                                        >
                                          <Image
                                            src={item}
                                            style={{
                                              maxWidth: '100%',
                                              height: 'auto',
                                            }} // Ensure the image scales with the column width and maintain its aspect ratio
                                          ></Image>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            <Divider />
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <Text textColor="white" fontSize="25">
                                Venue
                              </Text>
                              <Text textColor="white" fontSize="35">
                                {data.venueName}
                              </Text>
                              <Text textColor="white">{data.address}</Text>
                              <br></br>
                              {data.address && (
                                <Button
                                  onClick={() => handleDirections(data.address)}
                                  leftIcon={
                                    <HiOutlineLocationMarker
                                      style={{ height: 50 }}
                                    />
                                  }
                                >
                                  Open in Maps
                                </Button>
                              )}
                              <br />
                              <br />
                              {doorsOpen && (
                                <Text textColor="white">
                                  Doors open:{' '}
                                  {to12HrTime(doorsOpen.toTimeString())}
                                  {' on '}
                                  {doorsOpen.toDateString()}
                                </Text>
                              )}
                            </div>
                            <Divider />
                            <HStack>
                              <Box w={400}>
                                <div
                                  style={{
                                    paddingTop: 10,
                                    color: 'white',
                                    fontSize: 25,
                                  }}
                                >
                                  Download the Wave App
                                </div>
                                <div style={{ height: 10 }} />
                                <Text color="white">
                                  The best live shows, parties, and VIP
                                  experiences - all on one app. Catch the Wave
                                  today.
                                </Text>
                                <div style={{ height: 25 }} />
                                <HStack>
                                  <Button
                                    variant="solid"
                                    // leftIcon={<FaApple />}
                                    onClick={linkToAppStore}
                                    isDisabled
                                  >
                                    Coming Soon
                                    {/* iOS */}
                                  </Button>
                                  {/* <Button
                                  variant="solid"
                                  leftIcon={<AiFillAndroid />}
                                  onClick={() =>
                                    alert(
                                      'This will redirect to the android upon release'
                                    )
                                  }
                                >
                                  Android
                                </Button> */}
                                </HStack>
                              </Box>
                              <div style={{ width: 100 }} />
                              <Image src="/waveappicon1.png" boxSize="130" />
                            </HStack>
                          </div>
                        </div>
                      </HStack>
                    </Flex>
                  </Box>
                </Flex>
              </Center>
            </VStack>
          </div>
        )}
        <Drawer
          isOpen={isOpen}
          placement="right"
          initialFocusRef={firstField}
          onClose={closeDrawer}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              Complete your Purchase
            </DrawerHeader>
            <Elements stripe={stripePromise()} options={options}>
              {selectedTicket && (
                <MyPaymentForm
                  closeFunction={closeDrawer}
                  openDrawerFunction={onInfoOpen}
                  setCodeLoading={setCodesLoading}
                  passDetails={{
                    passName: `${data.passName}: ${selectedTicket.displayType}`,
                    datePurchased: new Date().toISOString(),
                    isActive: true,
                    eventTime: data.eventTime,
                    eventEndTime: data.eventEndTime,
                    venueId: data.venueId,
                    id: pathArray[2],
                    passImage: data.eventFlyerPicture,
                    ticketType: 'event',
                    price: intentPrice,
                    discountCode: usedDiscountCode,
                    numTickets: 0,
                    taxAmount: taxAmt,
                    waveFeeAmount: waveFee,
                  }}
                  id={pathArray[2]}
                  numTickets={ticketCount}
                  passPrice={intentPrice}
                  origPrice={selectedTicket.price}
                  ticketPackSize={ticketPackSize}
                  taxRate={taxRate}
                  serviceFeeRate={serviceFeeRate}
                />
              )}
            </Elements>
          </DrawerContent>
        </Drawer>
      </ChakraProvider>
      <ChakraProvider theme={theme}>
        <AlertDialog
          isOpen={isDiscountOpen}
          leastDestructiveRef={cancelRef}
          onClose={onDiscountClose}
          isCentered={true}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Discount Code Information
              </AlertDialogHeader>

              <AlertDialogBody>
                Enter your discount code below to receive a discount on your
                ticket!
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<FaTag />} />
                  <Input
                    type="text"
                    placeholder="Enter Discount Code"
                    value={discountCode}
                    onChange={e => setDiscountCode(e.target.value)}
                  />
                </InputGroup>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDiscountClose}>
                  No, Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={handleDiscountCode}
                  isLoading={discountButtonLoading}
                  ml={3}
                >
                  Yes, Submit
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </ChakraProvider>
      <ChakraProvider theme={theme}>
        <AlertDialog
          isOpen={isInfoOpen}
          leastDestructiveRef={cancelRef}
          onClose={onInfoClose}
          isCentered={true}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Ticket Information
              </AlertDialogHeader>
              {loadingCodes && (
                <AlertDialogBody>
                  Loading... Don't Reload The Page
                </AlertDialogBody>
              )}
              {!loadingCodes && (
                <div>
                  <AlertDialogBody>
                    Congratulations on your ticket purchase! Since you purchased
                    this ticket, your ticket is already attached to your
                    account. Press the button below to navigate to the profile
                    page, where you can share the codes for additional tickets
                    with your friends.
                  </AlertDialogBody>
                  <AlertDialogBody>
                    A Wave Pass is attached to an account, and your ID is your
                    ticket - no need to scan any barcodes at the venue, just
                    simply provide your ID and your ticket will be processed.
                  </AlertDialogBody>
                </div>
              )}

              <AlertDialogFooter>
                <Button
                  colorScheme="blue"
                  ref={cancelRef}
                  onClick={goToProfile}
                >
                  Continue
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </ChakraProvider>
    </>
  );
}

export default EventPage;
